import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Textarea,
    TextareaProps
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import ErrorHelper from '../../../../components/ErrorHelper'
import ThumbUp from '../../../../components/Icon/ThumbUp'
import modal, { close } from '../../../../lib/modal'
import onlyNumber from '../../../../utilities/onlyNumber'
import { formItem } from '../../storage'
import Preferences from '../Preferences'
import useSession from '../../../../hooks/useSession'

export default function Forms() {
    const { isWaiter, isLogged } = useSession()

    const isWaiter_ = isWaiter() || !isLogged

    return (
        <Box>
            <AddicionalInformation />
            {!isWaiter_ && <UnitPrice />}
        </Box>
    )
}

function AddicionalInformation() {
    const { props } = formItem.useField<TextareaProps>('description')

    function openHandler() {
        close()
        setTimeout(() => {
            modal(Preferences, {
                cleaned: true,
                size: 'lg',
                closeOnOverlayClick: false
            })
        }, 150)
    }

    return (
        <FormControl mb={4}>
            <FormLabel display="flex" justifyContent="flex-end">
                {/* Información Adicional */}
                <Button
                    size="sm"
                    bg="orange"
                    color="white"
                    mr="-3"
                    leftIcon={<ThumbUp fill="white" />}
                    onClick={openHandler}
                >
                    Preferencias
                </Button>
            </FormLabel>
            <Textarea
                {...props}
                placeholder="Ingresar información adicional"
                //isDisabled={isDisable_description && storagePage.editOrderTable}
            />
            <ErrorHelper helper="" />
        </FormControl>
    )
}

function UnitPrice() {
    const { props, helper } = formItem.useField<InputProps>('price')

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max: Infinity,
            negative: false
        })
        props.onChange({
            ...ev,
            target: {
                ...ev.target,
                value
            }
        })
    }

    return (
        <FormControl>
            <FormLabel>Precio unitario (S/)</FormLabel>
            <Input
                {...props}
                onChange={changeHandler}
                placeholder="Ingresar precio unitario"
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
