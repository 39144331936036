import FormEvent from '../lib/form_event'
import { post, put } from '../lib/http'
import { loading, close } from '../lib/modal'
import val from '../lib/val'
import { Val } from '../lib/val/type'
import { FormEvent as ReactFormEvent } from 'react'
import { Exception } from '../lib/http/type'

export default function useSubmit<T>(
    formEvent: FormEvent<T>,
    info: InfoSubmit
) {
    const tools: Tools<T> = {
        observer: () => formEvent.store(),
        valid: () => true
    }

    const validation = (validFn: (val: Val<T>, form: T) => void | boolean) => {
        tools.valid = validFn
    }

    function observer<B = FormBody<T>>(cb: (form: T) => B) {
        tools.observer = cb
    }

    async function submit(ev?: ReactFormEvent<HTMLFormElement>) {
        ev && ev.preventDefault()
        const v = val(formEvent.store())
        const indicator = tools.valid(v, formEvent.store())
        const result = v.runtest()
        if (result) {
            formEvent.setHelpers(result)
            return
        }

        if (indicator === false) {
            formEvent.setHelpers({})
            return
        }

        formEvent.setHelpers({})

        loading()
        const send = info.method === 'put' ? put : post
        const { error } = await send<any>(
            info.path,
            tools.observer(formEvent.store())
        )

        if (error) {
            formEvent.setError(error)
            info.error && info.error(error)
            loading(false)
        } else {
            close()
            info.done && info.done()
        }
    }

    return {
        validation,
        observer,
        submit
    }
}

interface InfoSubmit {
    path: string
    method?: 'put' | 'post'
    done?: () => void
    error?: (e: Exception<null>) => void
}

interface Tools<T> {
    valid: (val: Val<T>, form: T) => void | boolean
    observer: (form: T) => FormBody<T>
}

type FormBody<T> = Partial<Record<keyof T, any>> & Record<string, any>
