import useTab from '../../hooks/useTab'
import useDestiny from './Destiny/useDestiny'
import useItem from './Letter/useItem'
import useFeature from './Feature/useFeature'
import useSupply from './Supply/useSupply'
import useSession from '../../hooks/useSession'

export default function useControl() {
    const { isCUAccesible } = useSession()
    const openModalDestiny = useDestiny()
    const openModalItem = useItem()
    const openModalFeature = useFeature()
    const openModalSupply = useSupply()
    let props = useTab('item')

    if (!isCUAccesible('Visualizar productos')) {
        props = useTab('category')
    }

    if (
        !isCUAccesible('Visualizar productos') &&
        !isCUAccesible('Visualizar categorías')
    ) {
        props = useTab('supply')
    }

    if (
        !isCUAccesible('Visualizar productos') &&
        !isCUAccesible('Visualizar categorías') &&
        !isCUAccesible('Visualizar insumos')
    ) {
        props = useTab('feature')
    }

    function openModal() {
        if (props.value === 'item') {
            openModalItem()
        }
        if (props.value === 'destiny') {
            openModalDestiny()
        }
        if (props.value === 'supply') {
            openModalSupply()
        }
        if (props.value === 'feature') {
            openModalFeature()
        }
    }

    function getTextButton() {
        switch (props.value) {
            case 'item':
                return 'Nuevo producto'
            case 'category':
                return 'Nueva categoría'
            case 'supply':
                return 'Nuevo insumo'
            case 'feature':
                return 'Nueva característica'
            default:
                return 'Nuevo destino de preparación'
        }
    }

    function getPermission() {
        switch (props.value) {
            case 'item':
                return 'Crear producto'
            case 'category':
                return 'Crear categoría'
            case 'supply':
                return 'Crear insumo'
            case 'feature':
                return 'Crear característica'
            default:
                return 'Crear destino de preparación'
        }
    }

    return {
        openModal,
        getTextButton,
        tabProps: props,
        getPermission
    }
}
