import {
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useRender } from 'redity'
import states from './states'

export const Key = 'MODAL_FORM'

export default function ModalProvider() {
    const finalRef = useRef<any>()
    const initialRef = useRef<HTMLInputElement>()
    const render = useRender(Key)

    function onClose() {
        states.isOpen = false
        render()
    }

    function onOpen() {
        states.isOpen = true
        render()
    }

    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={states.isOpen}
            onClose={onClose}
            isCentered={states.isCentered}
            size={states.size}
            closeOnOverlayClick={states.closeOnOverlayClick}
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                {!states.cleaned && states.title && (
                    <ModalHeader
                        {...(states.centerTitle
                            ? {
                                  fontSize: '2xl',
                                  fontWeight: 'bold',
                                  textAlign: 'center'
                              }
                            : {})}
                    >
                        {states.title}
                    </ModalHeader>
                )}
                <form onSubmit={states.onSubmit} noValidate>
                    <ModalBody pb={6}>
                        <states.Component
                            initialRef={initialRef}
                            onClose={onClose}
                            onOpen={onOpen}
                        />
                    </ModalBody>
                    {!states.cleaned && (
                        <ModalFooter>
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-green:lg-md"
                                ml={3}
                                type="submit"
                                isLoading={states.isLoading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    )}
                </form>
            </ModalContent>
        </Modal>
    )
}
