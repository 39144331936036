import { Button, Flex, Select } from '@chakra-ui/react'
import Rules from '../../components/Rules'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import Category from './Category'
import ModalFormCategory from './Category/ModalFormCategory'
import Feature from './Feature'
import FiltersFeature from './Feature/FiltersFeature'
import Letter from './Letter'
import FiltersLetter from './Letter/FiltersLetter'
import { listCategory } from './storage'
import Supply from './Supply'
import FiltersSupply from './Supply/FiltersSupply'
import useControl from './useControl'
import useInitial from './useInitial'

export default function LetterManagement() {
    useInitial()
    const { tabProps, openModal, getTextButton, getPermission } = useControl()

    return (
        <Sidebar>
            <SimpleNav title="Gestión de productos" />
            <Tabs {...tabProps}>
                <Rules permission="Visualizar productos">
                    <Tab value="item">Productos</Tab>
                </Rules>
                <Rules permission="Visualizar categorías">
                    <Tab value="category">Categorías</Tab>
                </Rules>
                <Rules permission="Visualizar insumos">
                    <Tab value="supply">Insumos</Tab>
                </Rules>
                <Rules permission="Visualizar características">
                    <Tab value="feature">Características</Tab>
                </Rules>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <Rules permission="Visualizar productos">
                        <option value="item">Productos</option>
                    </Rules>
                    <Rules permission="Visualizar categorías">
                        <option value="category">Categorías</option>
                    </Rules>
                    <Rules permission="Visualizar insumos">
                        <option value="supply">Insumos</option>
                    </Rules>
                    <Rules permission="Visualizar características">
                        <option value="feature">Características</option>
                    </Rules>
                </Select>
                {tabProps.value !== 'category' && (
                    <Rules permission={getPermission()}>
                        <Button variant="alo-blue:md-xl" onClick={openModal}>
                            + Nuevo
                        </Button>
                    </Rules>
                )}
                {tabProps.value === 'category' && (
                    <ModalFormCategory
                        onDone={listCategory.load}
                        element={onOpen => (
                            <Rules permission={getPermission()}>
                                <Button
                                    variant="alo-blue:md-xl"
                                    onClick={onOpen}
                                >
                                    + Nuevo
                                </Button>
                            </Rules>
                        )}
                    />
                )}
            </Flex>
            <Flex
                mt={4}
                mb={4}
                w={{
                    xl: 'auto',
                    lg: tabProps.value === 'item' ? 'full' : 'auto'
                }}
                justifyContent={{
                    md:
                        tabProps.value === 'category'
                            ? 'flex-end'
                            : 'space-between',
                    base: 'flex-end'
                }}
            >
                {tabProps.value === 'item' && <FiltersLetter />}
                {tabProps.value === 'supply' && <FiltersSupply />}
                {tabProps.value === 'feature' && <FiltersFeature />}
                {tabProps.value !== 'category' && (
                    <Rules permission={getPermission()}>
                        <Button
                            variant="alo-blue:md-xl"
                            onClick={openModal}
                            display={{ base: 'none', md: 'inline' }}
                            ml="2"
                        >
                            {getTextButton()}
                        </Button>
                    </Rules>
                )}
                {tabProps.value === 'category' && (
                    <ModalFormCategory
                        onDone={listCategory.load}
                        element={onOpen => (
                            <Rules permission={getPermission()}>
                                <Button
                                    variant="alo-blue:md-xl"
                                    onClick={onOpen}
                                    display={{ base: 'none', md: 'inline' }}
                                >
                                    {getTextButton()}
                                </Button>
                            </Rules>
                        )}
                    />
                )}
            </Flex>
            {tabProps.value === 'item' && <Letter />}
            {tabProps.value === 'category' && <Category />}
            {tabProps.value === 'supply' && <Supply />}
            {tabProps.value === 'feature' && <Feature />}
        </Sidebar>
    )
}
