import colors from '../../../config/theme/colors'
import AssignmentTurned from '../../../components/Icon/AssignmentTurned'
import useCloseCashbox from '../hooks/useCloseCashbox'
import { Button, Tooltip } from '@chakra-ui/react'

export default function CloseCashbox() {
    const { closeCashbox, isYourBox } = useCloseCashbox()

    return (
        <Tooltip
            hasArrow
            label="Solo puede cerrar Caja el usuario que aperturó"
            bg="error"
            placement="top"
            shouldWrapChildren
            isDisabled={isYourBox}
        >
            <Button
                color="orange"
                border="2px solid orange"
                bg="white"
                borderRadius="md"
                cursor="pointer"
                w="auto"
                minW="100px"
                h="40px"
                _hover={{}}
                onClick={closeCashbox}
                isDisabled={!isYourBox}
                leftIcon={<AssignmentTurned fill={colors.orange} />}
            >
                Cerrar caja
            </Button>
        </Tooltip>
    )
}
