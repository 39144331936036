import { useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useRender } from 'redity'
import useRunway from '../../../hooks/useRunway'
import useSession from '../../../hooks/useSession'
import { post, put } from '../../../lib/http'
import modal, { close } from '../../../lib/modal'
import Table from '../../../models/table'
import Keys from '../keys'
import ContentModalLoading from '../ContentModals/ContentModalLoading'
import ContentModalWaiters from '../ContentModals/ContentModalWaiters'
import { storagePage } from '../storage'
import filterItems from '../utilities/filterItems'
import getItemsForPost from '../utilities/getItemsForPost'
import getItemsForPut from '../utilities/getItemsForPut'
import useStorage from '../../../hooks/useStorage'

export default function useConfirmSale() {
    useRender(Keys.CONTENT_BOTTOM)
    const params = useParams<{ table_id?: string }>()
    const { saveSelectedItems } = useRunway()
    const { push } = useHistory()
    const toast = useToast()
    const {
        isLogged,
        session,
        isAdmin,
        isWaiter,
        isCashier,
        userCanCancelItems
    } = useSession()

    const [openModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState({
        message: '',
        receipts: []
    })

    const { business_variables } = useStorage()
    const cashier_as_waiter = business_variables.find(
        v => v.code === '007'
    )?.value

    async function sendOrder(
        pin: string | null = null,
        user_id: number | null = null
    ) {
        modal(ContentModalLoading, {
            size: 'md',
            cleaned: true
        })

        const { error } = await post('/api/orders/request', {
            table_id: (storagePage.table as Table).id,
            pin,
            user_id,
            items: getItemsForPost(session ? session.user.id : null)
        })

        close()

        if (error) {
            //Valida si existen subcaracteristicas con insumos agotados
            if (error?.type === 'development' && error?.response) {
                setOpenModal(true)
                setDataModal({
                    message: error.message,
                    receipts: error.response
                })
                return
            }

            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
        } else {
            push('/by_tables')
            toast({
                title: 'Pedido confirmado',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
            saveSelectedItems([])
        }
    }

    function submitWaiterHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            waiter_id: { value: string }
        }
        if (formElem.waiter_id.value === '0') return
        close()
        sendOrder(null, parseInt(formElem.waiter_id.value))
    }

    async function update(reason: string | null = null) {
        modal(ContentModalLoading, {
            size: 'md',
            cleaned: true
        })

        const user_id = isLogged && session !== null ? session.user.id : null

        const { error } = await put(
            `/api/orders/${storagePage.table?.order.id}/request`,
            {
                table_id: (storagePage.table as Table).id,
                pin: storagePage.currentPin || null,
                order_items: getItemsForPut(user_id),
                reason,
                ...(reason && { user_id_cancel: user_id }),
                cashbox_id: storagePage.cashbox_id
            }
        )
        close()
        if (error) {
            //Valida si existen subcaracteristicas con insumos agotados
            if (error?.type === 'development' && error?.response) {
                setOpenModal(true)
                setDataModal({
                    message: error.message,
                    receipts: error.response
                })
                return
            }

            toast({
                title: error.message,
                position: 'top',
                isClosable: true,
                status: 'error'
            })
        } else {
            push('/by_tables')
            toast({
                title: 'Pedido actualizado',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
            saveSelectedItems([])
        }
    }

    function confirmHandler(pin = '') {
        if (filterItems(storagePage.selectedItems).length === 0) return
        if (params.table_id) {
            //Pedido con mesa
            if (storagePage.hasOrderTable) {
                update()
            } else {
                if (isLogged) {
                    if (
                        isWaiter() ||
                        (isCashier() && cashier_as_waiter == '1')
                    ) {
                        //Si el mozo ha iniciado sesión, Se envía id de mozo
                        session && sendOrder(null, session.user.id)
                    } else {
                        //Para un perfil logeado diferente a Mozo, pide Mozo
                        modal(ContentModalWaiters, {
                            size: '3xl',
                            title: 'Procesar pedido',
                            onSubmit: submitWaiterHandler,
                            isCentered: false
                        })
                    }
                } else {
                    sendOrder(pin)
                }
            }
        } else {
            //Venta directa
            saveSelectedItems(storagePage.selectedItems)
            push('/confirm')
        }
    }

    function payHandler() {
        if (filterItems(storagePage.selectedItems).length === 0) return
        saveSelectedItems(storagePage.selectedItems)
        push(`/orders/${storagePage.table?.order.id}/confirm`)
    }

    return {
        openModal,
        dataModal,
        setOpenModal,
        confirmHandler,
        payHandler,
        isWaiter,
        isAdmin,
        isLogged,
        update,
        userCanCancelItems
    }
}
