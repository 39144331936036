import {
    Box,
    Flex,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    Icon,
    useDisclosure
} from '@chakra-ui/react'
import Link from '../Sidebar/Link'
import LogoBox from '../Sidebar/LogoBox'
import dataLinks from '../Sidebar/dataLinks'
import Hamburguer from '../Icon/Hamburguer'
import useStorage from '../../hooks/useStorage'
import useSession from '../../hooks/useSession'

export default function SidebarMenu() {
    const { isCUAccesible, isAdmin } = useSession()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { business_variables } = useStorage()
    const enable_warehouse = business_variables.find(
        v => v.code === '006'
    )?.value
    return (
        <Flex pr="4">
            <Icon
                as={Hamburguer}
                fill={'blackAlpha.600'}
                cursor="pointer"
                fontSize="2xl"
                aria-label="Open menu"
                onClick={onOpen}
                _hover={{ fill: 'blackAlpha.500' }}
                pt={{ base: '0', md: '1' }}
            ></Icon>
            <Box>
                <Drawer isOpen={isOpen} onClose={onClose} placement="left">
                    <DrawerOverlay />
                    <DrawerContent maxW={'fit-content'}>
                        <Box w="232px" bg="black" color="white" h="100vh">
                            <Flex>
                                <Flex direction="column" py={8} w="full">
                                    <LogoBox />
                                    {dataLinks
                                        .filter(
                                            r =>
                                                !(
                                                    r.path == '/warehouses' &&
                                                    enable_warehouse === '0'
                                                )
                                        ) //Si el modulo Almacen esta desactivado no se muestra
                                        .map(route =>
                                            route.permission
                                                ? isCUAccesible(
                                                      route.permission
                                                  ) && (
                                                      <Link
                                                          key={route.path}
                                                          label={route.label}
                                                          Icon={route.Icon}
                                                          path={route.path}
                                                      />
                                                  )
                                                : isAdmin() && (
                                                      <Link
                                                          key={route.path}
                                                          label={route.label}
                                                          Icon={route.Icon}
                                                          path={route.path}
                                                      />
                                                  )
                                        )}
                                </Flex>
                            </Flex>
                        </Box>
                    </DrawerContent>
                </Drawer>
            </Box>
        </Flex>
    )
}
