import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import Dns from '../../../components/Icon/Dns'
import colors from '../../../config/theme/colors'
import { post } from '../../../lib/http'
import CashboxUser from '../../../models/cashbox_user'
// import getIPsConfig from '../../../utilities/getIpsConfig'
import getPrinterConfig from '../../../utilities/getPrinterConfig'
import CustomButton from '../Components/CustomButton'
import { storagePage } from '../storage'

export default function ClosePartialCashbox() {
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function clickHandler() {
        const cashboxUser = storagePage.cashboxUser as CashboxUser

        setLoading(true)
        // const ips = getIPsConfig()
        const printerConfig = getPrinterConfig()
        if (printerConfig) {
            const res = await post('/api/printer/close_cashbox', {
                cashbox_user_id: cashboxUser.id,
                printer_id: Number(printerConfig.printer_id),
                is_partial: true
            })
            if (res.error) {
                toast({
                    title: 'Lo sentimos, hubo problemas al intentar cerrar parcialmente. Vuelva a intentar',
                    status: 'error',
                    position: 'top'
                })
            } else {
                toast({
                    title: 'Cierre de caja parcial completado',
                    status: 'success',
                    position: 'top'
                })
            }
        } else {
            toast({
                title: 'Lo sentimos, se requiere configurar la impresora para realizar el cierre de caja parcial',
                status: 'error',
                position: 'top'
            })
        }

        setLoading(false)
    }

    return (
        <CustomButton
            isLoading={isLoading}
            onClick={clickHandler}
            leftIcon={<Dns fill={colors.orange} />}
        >
            Cierre parcial
        </CustomButton>
    )
}
