import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useRunway from '../../hooks/useRunway'
import useSession from '../../hooks/useSession'
import { get } from '../../lib/http'
import { Result } from '../../lib/http/type'
import CashboxUser from '../../models/cashbox_user'
import CategoryItem from '../../models/category_item'
import PageState from '../../types/pageState'
import { ItemData, storagePage } from './storage'
import { TableData } from './types'
import orderItemsToSelectedItems from './utilities/orderItemsToSelectedItems'

export default function useInitial() {
    const { selectedItems } = useRunway()
    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
    const { table_id } = useParams<{ table_id?: string }>()
    const { isCashier, isAdmin, isLogged } = useSession()
    const isCashier_ = isCashier() && isLogged

    async function loadApis() {
        const omitCashboxUser = Promise.resolve({
            data: {
                result: null
            },
            error: null
        })
        const request: Array<Promise<any>> = [
            get<CategoryItem[]>('/api/category_items?sort=order')
        ]

        if (table_id) {
            if (isCashier_ || isAdmin()) {
                request.push(
                    get<CashboxUser>('/api/cashbox_users/cash_box_user')
                )
            } else {
                request.push(omitCashboxUser)
            }
            request.push(get<TableData>(`/api/tables/${table_id}`))
            request.push(get<boolean>(`/api/tables/${table_id}/has_order`))
        } else {
            request.push(get<CashboxUser>('/api/cashbox_users/cash_box_user'))
        }

        const [result1, result2, result3, result4] = (await Promise.all(
            request
        )) as [
            Result<CategoryItem[]>,
            Result<CashboxUser | null>,
            Result<TableData>,
            Result<boolean>
        ]

        if (result1.error || result2.error) {
            setPageState(PageState.ERROR)
            return
        }

        if (table_id && (result3.error || result4.error)) {
            setPageState(PageState.ERROR)
            return
        }

        storagePage.categories = result1.data.result
        storagePage.isOpenCashbox = !!result2.data.result
        storagePage.cashbox_id = result2.data.result?.cashbox_id || null
        storagePage.selectedCategoryId =
            result1.data.result.length > 0 ? result1.data.result[0].id : 0
        if (table_id) {
            storagePage.table = result3.data.result
            storagePage.hasOrderTable = result4.data.result
            storagePage.selectedItems = orderItemsToSelectedItems(
                result3.data.result.order_items
            )
        }

        const query = storagePage.selectedCategoryId
            ? `?category_item_id=${storagePage.selectedCategoryId}`
            : ''

        const { error, data } = await get<ItemData[]>(`/api/items${query}`)

        if (error) {
            setPageState(PageState.ERROR)
            return
        }

        storagePage.items = data.result

        setPageState(PageState.SUCCESS)
    }

    useEffect(() => {
        storagePage.selectedItems = [...selectedItems]
        loadApis()
        return () => {
            storagePage.selectedItems = []
            storagePage.items = []
            storagePage.table = null
            storagePage.hasOrderTable = false
            storagePage.editOrderTable = false
            storagePage.currentPin = ''
        }
    }, [])

    return {
        pageState,
        isCashier_
    }
}
