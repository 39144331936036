import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react'
import { render } from 'redity'
import ModalPin from '../../../components/ModalPin'
import useSession from '../../../hooks/useSession'
import getInformationForWaiter from '../../../utilities/getInformationForWaiter'
import Keys from '../keys'
import { storagePage } from '../storage'
import ListItems from './ListItems'
import useDialog from '../../../hooks/useDialog'
import modal from '../../../lib/modal'
import ChangeTable from '../ContentModals/ChangeTable'
import ChangeWaiter from '../ContentModals/ChangeWaiter'
import ContentModalWaiters from '../ContentModals/ContentModalWaiters'
import useStorage from '../../../hooks/useStorage'
import CommandHistory from '../ContentModals/CommandHistory'
import useMoveProducts from '../hooks/useMoveProducts'

export default function ContentTop() {
    const { moveProductsHandler } = useMoveProducts()
    function cleanHandler() {
        if (storagePage.hasOrderTable) {
            for (let i = 0; i < storagePage.selectedItems.length; i++) {
                const selectedCategory = storagePage.selectedItems[i]
                for (let j = 0; j < selectedCategory.items.length; j++) {
                    const currentItem = selectedCategory.items[j]
                    currentItem.quantity = 0
                }
            }
        } else {
            storagePage.selectedItems = []
        }

        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
    }

    function editOrderTableHandler() {
        storagePage.editOrderTable = true
        render(Keys.CONTENT_LEFT)
        render(Keys.CONTENT_RIGHT)
    }

    const dialogInvalidEditOrder = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                No puede editar este pedido. Usted no está atendiendo esta mesa.
            </>
        )
    })

    const openModalChangeTable = () => {
        if (!hasOrderTable) {
            return
        }
        modal(ChangeTable, {
            size: '3xl',
            cleaned: true
        })
    }

    const handleChangeWaiter = ChangeWaiter()
    const openModalChangeWaiter = () => {
        modal(ContentModalWaiters, {
            size: '3xl',
            title: 'Cambio de mozo',
            isCentered: false,
            onSubmit: handleChangeWaiter
        })
    }

    const openModalCommandHistory = () => {
        modal(CommandHistory, {
            size: '3xl',
            cleaned: true,
            isCentered: false
        })
    }

    const { business_variables } = useStorage()
    const edit_all_waiters = business_variables.find(
        v => v.code === '005'
    )?.value

    const cashier_as_waiter = business_variables.find(
        v => v.code === '007'
    )?.value

    const command_history_enable = business_variables.find(
        v => v.code === '008'
    )?.value

    function editOrderValidate() {
        if (allowEditOrder || edit_all_waiters === '1') {
            editOrderTableHandler()
        } else {
            dialogInvalidEditOrder()
        }
    }

    const { editOrderTable, hasOrderTable, table } = storagePage
    const { isLogged, session, isAdmin, isCashier, isWaiter } = useSession()

    const waiterId = table?.waiter?.id
    const userId = session ? session.user.id : 0
    const allowEditOrder = userId == waiterId || isAdmin() || isCashier()
    const isWaiter_ = isWaiter() || !isLogged

    return (
        <Box>
            <Flex justifyContent="space-between" px="6" mb={2}>
                <Heading
                    size="17px"
                    color="dark"
                    marginLeft={{ base: '40px', lg: 0 }}
                >
                    Detalle de pedido
                </Heading>
                {hasOrderTable && !editOrderTable ? (
                    <ModalPin
                        title="Procesar pedido"
                        validate={{
                            order_id: storagePage.table?.order.id as number
                        }}
                        element={click => (
                            <Button
                                variant="primary:sm-xl"
                                bg="transparent"
                                color="primary"
                                onClick={isLogged ? editOrderValidate : click}
                            >
                                Editar pedido
                            </Button>
                        )}
                        onDone={nextPin => {
                            storagePage.currentPin = nextPin
                            editOrderTableHandler()
                        }}
                    />
                ) : (
                    !isWaiter_ && (
                        <Button
                            variant="primary:sm-xl"
                            bg="transparent"
                            color="primary"
                            onClick={cleanHandler}
                        >
                            Limpiar pedido
                        </Button>
                    )
                )}
            </Flex>
            {table && (
                <>
                    <Flex
                        px="6"
                        mb={3}
                        mt={-1}
                        justifyContent="space-between"
                        fontSize="sm"
                        color="gray-3"
                        marginLeft={{ base: '40px', lg: 0 }}
                    >
                        {table.waiter && (
                            <Text>
                                Mozo:{' '}
                                <Text
                                    color="dark-2"
                                    as={'b'}
                                    {...((isAdmin() ||
                                        (isCashier() &&
                                            cashier_as_waiter != '1')) && {
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        onClick: openModalChangeWaiter
                                    })}
                                >
                                    {getInformationForWaiter(
                                        table.waiter.firstname,
                                        table.waiter.lastname
                                    )}
                                </Text>
                            </Text>
                        )}
                        <Text>
                            Mesa:{' '}
                            <Text
                                color="dark-2"
                                textDecoration={
                                    hasOrderTable ? 'underline' : 'none'
                                }
                                cursor={hasOrderTable ? 'pointer' : 'text'}
                                as={'b'}
                                onClick={openModalChangeTable}
                            >
                                {table.name}
                            </Text>
                        </Text>
                    </Flex>
                    {hasOrderTable && !isWaiter_ && (
                        <Flex
                            px="6"
                            mb={3}
                            mt={-1}
                            fontSize="sm"
                            marginLeft={{ base: '40px', lg: 0 }}
                        >
                            <Text
                                color="dark-2"
                                textDecoration={'underline'}
                                cursor={'pointer'}
                                as={'b'}
                                onClick={moveProductsHandler}
                            >
                                Mover productos
                            </Text>
                        </Flex>
                    )}
                    {hasOrderTable &&
                        command_history_enable === '1' &&
                        !isWaiter_ && (
                            <Flex
                                px="6"
                                mb={3}
                                mt={-1}
                                fontSize="sm"
                                marginLeft={{ base: '40px', lg: 0 }}
                            >
                                <Text
                                    color="dark-2"
                                    textDecoration="underline"
                                    cursor="pointer"
                                    as={'b'}
                                    onClick={openModalCommandHistory}
                                >
                                    Historial de comandas
                                </Text>
                            </Flex>
                        )}
                </>
            )}
            <Divider mb="2" />
            <ListItems />
        </Box>
    )
}
