import { Box, Flex, HStack } from '@chakra-ui/react'
import Details from './Details'
import MainContent from './MainContent'
import NavbarAlt from '../../components/NavbarAlt'
import colors from '../../config/theme/colors'
import useInitial from './useInitial'
import Initial from '../../components/Initial'
import { storagePage } from './storage'

export default function ConfirmOrder() {
    const { pageState, order_id, account_id } = useInitial()

    let path
    if (order_id && account_id) {
        path = `/orders/${order_id}/confirm/split`
    } else if (order_id && !account_id) {
        path = `/by_tables/${storagePage.table_id}/quick_sale`
    } else {
        path = `/quick_sale`
    }

    return (
        <Box minH="100vh" bg="base-4">
            <NavbarAlt title="Confirmar pedido" path={path} />
            <Initial pageState={pageState} h="calc(100vh - 60px)">
                <HStack
                    w="full"
                    align="stretch"
                    display="flex"
                    flexDirection={{ base: 'column', md: 'row' }}
                >
                    <Box
                        w="full"
                        px={{
                            xl: '16',
                            base: '1'
                        }}
                        py={{
                            xl: '10',
                            base: '6'
                        }}
                        bg="white"
                        minH={{ base: 'fit-content', md: 'calc(100vh - 60px)' }}
                    >
                        <MainContent />
                    </Box>
                    <Flex
                        minW={{
                            xl: '550px',
                            lg: '400px'
                        }}
                        minH={{ base: 'fit-content', md: 'calc(100vh - 60px)' }}
                        color={colors['alo-gray']}
                        p={{
                            base: '4',
                            md: '6'
                        }}
                        flexDirection="column"
                        marginLeft={0}
                    >
                        <Details />
                    </Flex>
                </HStack>
            </Initial>
        </Box>
    )
}
