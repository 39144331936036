import { Tr, Td } from '@chakra-ui/react'
import Rules from '../../../components/Rules'
import List from '../../../lib/list'
import { listSupply } from '../storage'
import DeleteSupply from './DeleteSupply'
import Edition from './Edition'
import EditionStock from './EditionStock'

export default function Supply() {
    return (
        <List
            control={listSupply}
            tbody={(supply, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{supply.name}</Td>
                    <Td textAlign="center">{supply.type_measure.name}</Td>
                    <Td textAlign="center">
                        {supply.has_inventory_control ? supply.stock : '-'}
                    </Td>
                    <Td textAlign="center">
                        <Rules permission="Actualizar inventario de insumo">
                            <EditionStock supply={supply} />
                        </Rules>
                        <Rules permission="Editar insumo">
                            <Edition supply={supply} />
                        </Rules>
                        <Rules permission="Eliminar insumo">
                            <DeleteSupply supply={supply} />
                        </Rules>
                    </Td>
                </Tr>
            )}
        />
    )
}
