import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useGet } from '../../../../hooks/useHttp'
import { TableData } from '../../../ByTables/types'

export default function Tables({
    zoneId,
    tableId,
    setTableId
}: {
    zoneId: number
    tableId: number
    setTableId: (tableId: number) => void
}) {
    const { data, setQuery } = useGet<TableData[]>(
        '/api/tables/available/all',
        []
    )

    useEffect(() => {
        setQuery('zone_id', zoneId)
    }, [zoneId])

    return (
        <>
            {data.result.length === 0 && (
                <Text py="5" fontSize={'lg'}>
                    No hay mesas disponibles
                </Text>
            )}
            {data.result.length !== 0 && (
                <SimpleGrid
                    spacing={3}
                    columns={[2, 4]}
                    py={5}
                    // minChildWidth={{
                    //     base: '150px',
                    //     xl: '180px'
                    // }}
                >
                    {data.result.map(table => (
                        <Box
                            key={table.id}
                            cursor="pointer"
                            borderRadius="lg"
                            onClick={() => setTableId(table.id)}
                            h="120px"
                            bg={table.id !== tableId ? 'gray-3' : 'primary'}
                            p="4"
                            color="white"
                            textAlign="center"
                            display="flex"
                            alignItems={'center'}
                            justifyContent="center"
                        >
                            <Heading size="md" textAlign="center">
                                {table.name}
                            </Heading>
                        </Box>
                    ))}
                </SimpleGrid>
            )}
        </>
    )
}
