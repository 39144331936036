import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import ClosePartialCashbox from './ClosePartialCashbox'
import Moviments from './Moviments'
import { storagePage } from '../storage'
import TotalCash from './TotalCash'
import TotalSale from './TotalSale'
import useSession from '../../../hooks/useSession'
import CashboxField from './CashboxField'
import OpenCashbox from './OpenCashbox'
import CloseCashbox from './CloseCashbox'
import BoxTotal from '../Components/BoxTotal'
import getInformationForWaiter from '../../../utilities/getInformationForWaiter'

export default function Main() {
    const { cashboxUser } = storagePage
    const { isAdmin } = useSession()

    return (
        <>
            <Flex alignItems="center" color="dark-2" mb={6} mt={10}>
                <Heading size="lg" mr={2}>
                    Tablero de caja:{' '}
                    {isAdmin() ? '' : cashboxUser?.cashbox.name}
                </Heading>
                {isAdmin() && <CashboxField />}
            </Flex>

            <Box
                display="flex"
                flexDirection={{ base: 'column', lg: 'row' }}
                justifyContent={cashboxUser ? 'space-between' : 'center'}
                alignItems={{ base: 'flex-start', lg: 'center' }}
                gridGap={4}
            >
                {cashboxUser && (
                    <Flex
                        color="dark"
                        fontWeight="bold"
                        fontSize="lg"
                        whiteSpace="nowrap"
                        gridGap={{ base: 2, md: 4 }}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Flex gridGap={1.5}>
                            Aperturado:{' '}
                            <Text as="span" color="gray-2" fontWeight="normal">
                                {dayjs(cashboxUser.started_at).format(
                                    'DD/MM/YYYY hh:mm A'
                                )}
                            </Text>
                        </Flex>
                        <Flex gridGap={1.5}>
                            Por:{' '}
                            <Text as="span" color="gray-2" fontWeight="normal">
                                {getInformationForWaiter(
                                    cashboxUser.user.firstname,
                                    cashboxUser.user.lastname
                                )}
                            </Text>
                        </Flex>
                    </Flex>
                )}

                <Box display="flex" gridGap="10px">
                    {cashboxUser && <ClosePartialCashbox />}
                    {!cashboxUser && <OpenCashbox />}
                    {cashboxUser && <CloseCashbox />}
                </Box>
            </Box>
            {cashboxUser && (
                <Box>
                    <Box
                        mt="4"
                        display="flex"
                        flexDirection={{ base: 'column', md: 'row' }}
                        gridGap={{
                            md: '4',
                            base: '2'
                        }}
                    >
                        <Flex
                            gridGap={{
                                md: '4',
                                base: '2'
                            }}
                            flexDirection={{ base: 'column', xl: 'row' }}
                            w={{ base: '100%', md: '50%' }}
                        >
                            <TotalSale />
                            <BoxTotal
                                w={{ base: '100%', xl: '30%' }}
                                title="Total por cobrar"
                                value={cashboxUser.receivable}
                                wTarget={null}
                            />
                        </Flex>
                        <TotalCash />
                    </Box>
                    <Moviments />
                </Box>
            )}
        </>
    )
}
