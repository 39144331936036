import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import { listCategory } from '../storage'
import { ListCategory } from '../types'
import ModalFormCategory from './ModalFormCategory'

interface EditionProps {
    category: ListCategory
}

export default function Edition({ category }: EditionProps) {
    return (
        <ModalFormCategory
            edition={category}
            onDone={listCategory.load}
            element={onOpen => (
                <Icon
                    fill={Colors.INFO}
                    as={Pencil}
                    marginRight={2}
                    cursor="pointer"
                    onClick={onOpen}
                />
            )}
        />
    )
}
