export default function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M.023 13.046l1.704 1.704 7.25-7.25L1.727.25.023 1.954 5.557 7.5.023 13.046z" />
        </svg>
    )
}
