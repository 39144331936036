export default function BackSpace(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="3em"
            height="3em"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M144 96L32 256l112 160h304V96zm215.3 226.34L336.67 345l-65-65-65 65L184 322.34l65-65-65-65 22.63-22.63 65 65 65-65 22.63 22.63-65 65z" />
        </svg>
    )
}
