import { Box, Icon } from '@chakra-ui/react'
import { useCallback, useEffect, useRef } from 'react'
import Close from '../Icon/Close'
import { CleanProps } from './types'

export default function Clean({ children, onClick, value }: CleanProps) {
    const ref = useRef<HTMLDivElement>(null)

    const listener = useCallback(function (ev: KeyboardEvent) {
        if (ev.key === 'Escape') {
            onClick()
        }
    }, [])

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('keyup', listener)
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('keyup', listener)
            }
        }
    }, [])

    return (
        <Box position="relative" ref={ref}>
            {children}
            {value && (
                <Icon
                    as={Close}
                    fill="gray-3"
                    position="absolute"
                    top="calc(50% - 7px)"
                    right="4"
                    fontSize="sm"
                    cursor="pointer"
                    zIndex="1"
                    onClick={onClick}
                />
            )}
        </Box>
    )
}
