export default function ArrowLeft(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M8.977 1.954L7.273.25.023 7.5l7.25 7.25 1.704-1.704L3.443 7.5l5.534-5.546z" />
        </svg>
    )
}
