import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    Select,
    SelectProps
} from '@chakra-ui/react'
import { useGet } from '../../hooks/useHttp'
import { post } from '../../lib/http'
import PrinterMachine from '../../models/printer_machine'
import ErrorHelper from '../ErrorHelper'
import Printer from '../Icon/Printer'
import usePrinterConfig, { formPrinter } from './usePrinterConfig'

export default function PrinterConfig() {
    const { clickHandler, isDone } = usePrinterConfig(() => (
        <>
            <SelectPrinter />
        </>
    ))

    return (
        <Icon
            as={Printer}
            fill={isDone ? 'success' : 'error'}
            cursor="pointer"
            fontSize="3xl"
            mr={4}
            onClick={clickHandler}
        />
    )
}

function SelectPrinter() {
    const { props, helper } = formPrinter.useField<SelectProps>('printer_id')
    const { data } = useGet<PrinterMachine[]>('/api/printer_machines', [])
    const printerMachines = data.result.map(printerMachine => {
        return {
            label: printerMachine.name,
            value: printerMachine.id
        }
    })

    const printerTest = async () => {
        await post('/api/v2/printers/print_test', {
            printer_id: Number(props.value)
        })
    }

    return (
        <FormControl mb={6} position="relative">
            <FormLabel>Impresora</FormLabel>
            <Select {...props} placeholder="Seleccionar Impresora" autoFocus>
                {printerMachines.map(printerMachine => (
                    <option
                        key={printerMachine.value}
                        value={printerMachine.value}
                    >
                        {printerMachine.label}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
            {!!props.value && (
                <Button
                    mt="5"
                    onClick={printerTest}
                    variant="outline"
                    color="#4E6BD3"
                    borderRadius="full"
                    borderColor="#4E6BD3"
                    leftIcon={<Printer fill="#4E6BD3" fontSize={24} />}
                >
                    Probar impresora
                </Button>
            )}
        </FormControl>
    )
}
