import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import getVariantsButton from './getVariantsButton'

const theme = extendTheme({
    colors,
    components: {
        Button: {
            variants: {
                ...getVariantsButton('primary'),
                ...getVariantsButton('secondary'),
                ...getVariantsButton('accent'),
                ...getVariantsButton('alo-blue'),
                ...getVariantsButton('alo-red'),
                ...getVariantsButton('yellow'),
                ...getVariantsButton('alo-green'),
                ...getVariantsButton('orange'),
                ...getVariantsButton('purple'),
                ...getVariantsButton('turquoise'),
                ...getVariantsButton('black'),
                ...getVariantsButton('dark'),
                ...getVariantsButton('dark-2'),
                ...getVariantsButton('alo-gray'),
                ...getVariantsButton('gray-2'),
                ...getVariantsButton('gray-3'),
                ...getVariantsButton('white'),
                ...getVariantsButton('base'),
                ...getVariantsButton('base-2'),
                ...getVariantsButton('base-3')
            }
        }
    }
})

export default theme
