import { Button, Tooltip } from '@chakra-ui/react'
import AssignmentTurned from '../../../components/Icon/AssignmentTurned'
import colors from '../../../config/theme/colors'
import useOpenCashbox from '../hooks/useOpenCashbox'

export default function OpenCashbox() {
    const { openCashbox, isDisabled } = useOpenCashbox()

    return (
        <Tooltip
            hasArrow
            label="Nos es posible aperturar otra caja si aún tiene una caja aperturada"
            bg="error"
            placement="top"
            shouldWrapChildren
            isDisabled={!isDisabled}
        >
            <Button
                color="orange"
                border="2px solid orange"
                bg="white"
                borderRadius="md"
                cursor="pointer"
                isDisabled={isDisabled}
                w="auto"
                minW="100px"
                h="40px"
                _hover={{}}
                onClick={openCashbox}
                leftIcon={<AssignmentTurned fill={colors.orange} />}
            >
                Aperturar caja
            </Button>
        </Tooltip>
    )
}
