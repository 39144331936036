import { Select } from '@chakra-ui/react'
import Zone from '../../models/zone'
import { ChangeEvent } from 'react'

export default function SelectZones({
    zones,
    currentZone,
    onClick
}: {
    zones: Zone[]
    currentZone: number
    onClick: (zoneId: number) => void
}) {
    async function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        onClick(parseInt(ev.target.value))
    }

    return (
        <Select onChange={changeHandler} value={currentZone}>
            {zones.map(zone => (
                <option key={zone.id} value={zone.id}>
                    {zone.name}
                </option>
            ))}
        </Select>
    )
}
