import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import PrinterMachine, { IPPrinter } from '../../models/printer_machine'
import Zone from '../../models/zone'
import { FormPrinterMachine } from './types'

interface PrinterMachineList extends PrinterMachine {
    zone: Zone | null
    ip_printer: IPPrinter
}

export const controlList = new ControlList<PrinterMachineList>({
    path: '/api/printer_machines',
    theads: ['Nombre', 'Tipo de conexión', 'IP', 'Zona', 'Acciones']
})

export const formPrinterMachine = new FormEvent<FormPrinterMachine>({
    name: '',
    ip: '',
    type_printer: '',
    zone_id: ''
})
