import { render } from 'redity'
import CategoryItem from '../../../../models/category_item'
import Item from '../../../../models/item'
import { FeatureSetting, SelectedItem } from '../../../../types/runway_state'
import { generateId } from '../../../../utilities/generateId'
import Keys from '../../keys'
import { FeatureSelected } from '../../ContentModals/IceCream/types'
import { storagePage } from '../../storage'
import getFeatureSettingSubFeatures from '../../utilities/getFeatureSettingSubFeatures'
import { compareFeatureSetting, getItemsSelected } from './utilities'
import Big from 'big.js'

export default function useAddItems(
    item: Item & {
        category_item: CategoryItem
    }
) {
    const { selectedItems } = storagePage
    const getItemForAdd = (
        quantity: number,
        feature_settings: FeatureSetting[],
        idGenerated?: string,
        features_price?: number
    ) => ({
        id: item.id,
        _id_generated: idGenerated,
        name: item.name,
        quantity,
        quantity_recorded: quantity,
        price: new Big(item.price).plus(features_price || 0).toNumber(),
        description: '',
        user_price_edition: 0,
        feature_settings
    })

    function addItem() {
        const { items: currentItems, category } = getItemsSelected(
            item.category_item_id,
            item.id
        )
        if (currentItems[0]) {
            currentItems[0].quantity = ++currentItems[0].quantity
        } else {
            contitionalPushItem(category)
        }
        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
        render(Keys.BUTTON_SEE_ORDER)
    }

    function addItemWithFeatures(
        featureSelected: FeatureSelected[],
        quantity: number
    ) {
        const { items: currentItems, category } = getItemsSelected(
            item.category_item_id,
            item.id
        )

        const featureSettings = featureSelected.map(fs => ({
            feature_id: fs.feature_id,
            quantity: fs.quantity,
            name: fs.name,
            feature_setting_sub_features: getFeatureSettingSubFeatures(fs.subs)
        }))

        const features_price = featureSettings.reduce(
            (a, b) =>
                a +
                b.feature_setting_sub_features.reduce((c, d) => c + d.price, 0),
            0
        )

        if (currentItems.length > 0) {
            let isFinded = false
            for (const currentItem of currentItems) {
                if (
                    compareFeatureSetting(
                        currentItem.feature_settings,
                        featureSettings
                    )
                ) {
                    currentItem.quantity = currentItem.quantity + quantity
                    isFinded = true
                    break
                }
            }
            if (!isFinded) {
                contitionalPushItem(
                    category,
                    quantity,
                    featureSettings,
                    features_price
                )
            }
        } else {
            contitionalPushItem(
                category,
                quantity,
                featureSettings,
                features_price
            )
        }

        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
        render(Keys.BUTTON_SEE_ORDER)
    }

    function contitionalPushItem(
        category: SelectedItem | undefined,
        quantity = 1,
        featureSettings: FeatureSetting[] = [],
        features_price = 0
    ) {
        const generatedId =
            featureSettings.length > 0 ? generateId() : undefined
        if (category) {
            category.items.push(
                getItemForAdd(
                    quantity,
                    featureSettings,
                    generatedId,
                    features_price
                )
            )
        } else {
            selectedItems.push({
                category_item_id: item.category_item_id,
                category_item_name: item.category_item.name,
                items: [
                    getItemForAdd(
                        quantity,
                        featureSettings,
                        generatedId,
                        features_price
                    )
                ]
            })
        }
    }

    return {
        addItem,
        addItemWithFeatures
    }
}
