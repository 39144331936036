import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from '../../lib/http'
import PageState from '../../types/pageState'
import { storagePage } from './storage'
import { OrderAccount } from './types'

export default function useInitial() {
    const [pageState, setPageState] = useState(PageState.LOADING)
    const { order_id } = useParams<{ order_id?: string }>()

    async function load() {
        const { error, data } = await get<OrderAccount>(
            `/api/account_orders/${order_id}/order`
        )
        if (error) {
            setPageState(PageState.ERROR)
            return
        }
        storagePage.account_orders = data.result.account_orders
        storagePage.matrix_items = data.result.matrix_items
        setPageState(PageState.SUCCESS)
    }

    useEffect(() => {
        load()
        return () => {
            storagePage.items = []
            storagePage.items_to_divide = []
        }
    }, [])

    return {
        pageState,
        order_id
    }
}
