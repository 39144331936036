import {
    Box,
    Heading,
    useToast,
    Switch,
    Icon,
    Flex,
    Text
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { put } from '../../../lib/http'
import Edition from './Edition'
import { listZone } from '../storage'
import hexRgb from 'hex-rgb'
import colors from '../../../config/theme/colors'
import Zone from '../../../models/zone'
import Table from '../../../components/Icon/Table'
import Rules from '../../../components/Rules'

const h = hexRgb as any

export default function CardZone({ zone }: CardZoneProps) {
    const [checked, setChecked] = useState(() => zone.is_enabled)
    const toast = useToast()

    async function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const is_enabled = ev.target.checked
        setChecked(is_enabled)
        const { error } = await put(`/api/zones/${zone.id}`, {
            name: zone.name,
            is_enabled
        })
        if (error) {
            setChecked(!is_enabled)
            toast({
                title: 'Hubo problemas al actualizar, vuelva a intentar',
                status: 'error',
                position: 'top',
                isClosable: true
            })
        } else {
            toast({
                title: is_enabled
                    ? 'Se ha habilitado la zona'
                    : 'Se ha deshabilitado la zone',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            listZone.load()
        }
    }

    return (
        <Box
            key={zone.id}
            borderRadius="10px"
            bg={
                zone.is_enabled
                    ? 'white'
                    : h(colors['gray-2'], { alpha: 0.1, format: 'css' })
            }
            w="100%"
            minH="114px"
            transition="ease"
            border="1px solid #E5E7EB"
            p="4"
        >
            <Heading
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
                mb={6}
                color={zone.is_enabled ? colors['alo-gray'] : colors.black}
            >
                {zone.name}
            </Heading>
            <Flex justifyContent="space-between" alignItems="flex-start">
                <Rules permission="Editar zona">
                    <Edition zone={zone} />
                </Rules>
                <Rules
                    permission={
                        zone.is_enabled ? 'Desactivar zona' : 'Activar zona'
                    }
                >
                    <Switch
                        colorScheme="green"
                        size="md"
                        isChecked={checked}
                        onChange={changeHandler}
                    />
                </Rules>
                <Flex alignItems="center">
                    <Icon as={Table} fill="dark-2" fontSize="xl" mr={2} />
                    <Text color="dark-2">{zone.n_tables}</Text>
                </Flex>
            </Flex>
        </Box>
    )
}

interface CardZoneProps {
    zone: Zone
}
