import { Tr, Td } from '@chakra-ui/react'
import Rules from '../../../components/Rules'
import List from '../../../lib/list'
import { listFeature } from '../storage'
import DeleteFeature from './DeleteFeature'
import Edition from './Edition'
import SubFeatures from './SubFeatures'

export default function Feature() {
    return (
        <List
            control={listFeature}
            tbody={(feature, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{feature?.name}</Td>
                    <Td textAlign="center">
                        {feature?.sub_features?.length === 0 ? (
                            0
                        ) : (
                            <SubFeatures feature={feature} />
                        )}
                    </Td>
                    <Td textAlign="center">
                        <Rules permission="Editar característica">
                            <Edition feature={feature} />
                        </Rules>
                        <Rules permission="Eliminar característica">
                            <DeleteFeature feature={feature} />
                        </Rules>
                    </Td>
                </Tr>
            )}
        />
    )
}
