import { useDisclosure, useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import { get } from '../../../../../lib/http'
import Feature from '../../../../../models/feature'
import { SubFeature } from '../../../../../models/sub_feature'
import { FeatureSelected, GetItem } from '../types'
import Big from 'big.js'

export default function useIceCream(
    itemId: number,
    onDone: (
        featuresSelected: FeatureSelected[],
        quantity: number,
        item_price_plus_features_price: number,
        idGenerated?: string
    ) => void,
    initialQuantity: number,
    edition?: FeatureSelected[]
) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [counter, setCounter] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [features, setFeatures] = useState<FeatureSelected[]>([])
    const [item, setItem] = useState<GetItem | null>(null)
    const toast = useToast()

    async function _onOpen() {
        onOpen()
        setLoading(true)
        const { data, error } = await get<GetItem>(`/api/items/${itemId}`)
        if (!error) {
            setItem(data.result)
            resetFeatures(data.result.item_features)
            if (edition) {
                setFeatures(nextFeatureSelected => {
                    return nextFeatureSelected.map(fs => {
                        const featureSelectedOfEdition = edition.find(
                            e => e.feature_id === fs.feature_id
                        )
                        if (featureSelectedOfEdition) {
                            return {
                                ...featureSelectedOfEdition,
                                subs: [...featureSelectedOfEdition.subs]
                            }
                        }
                        return fs
                    })
                })
            }
        }
        setLoading(false)
        setCounter(initialQuantity)
    }

    function _setFeature(
        feature: Feature,
        subFeature: SubFeature,
        quantity: number
    ) {
        const nextFeatures = [...features]
        const nextFeature = nextFeatures.find(
            f => f.feature_id === feature.id
        ) as FeatureSelected

        if (nextFeature.subs.length === quantity && quantity > 1) return
        if (quantity === 1) {
            nextFeature.subs = [
                {
                    sub_feature_id: subFeature.id,
                    name: subFeature.name,
                    price: subFeature.price,
                    aditional_information: ''
                }
            ]
            setFeatures(nextFeatures)
        } else {
            nextFeature.subs = [...nextFeature.subs]
            nextFeature.subs.push({
                sub_feature_id: subFeature.id,
                name: subFeature.name,
                price: subFeature.price,
                aditional_information: ''
            })
            setFeatures(nextFeatures)
        }
    }

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const base_price_item = item ? item.price : 0 //el item.price se vuelve a traer de la BD
        const acumulate_price_subfeatures = features.reduce(
            (a, b) => a + b.subs.reduce((c, d) => c + d.price, 0),
            0
        )

        const item_price_plus_features_price = new Big(base_price_item)
            .plus(acumulate_price_subfeatures)
            .toNumber()

        const formElem = ev.target as HTMLFormElement
        if (formElem.name === 'form-information') return
        const item_features = item?.item_features as GetItem['item_features']
        for (const itemFeature of item_features) {
            const featureSelected = features.find(
                f => f.feature_id === itemFeature.feature_id
            )
            if (featureSelected) {
                const subsQuantity = featureSelected.subs.length
                if (
                    itemFeature.quantity !== subsQuantity &&
                    itemFeature.quantity > 0
                ) {
                    toast({
                        title: `Seleccione ${featureSelected.quantity} ${featureSelected.name}`,
                        status: 'error',
                        position: 'top',
                        isClosable: true
                    })
                    return
                }
            } else if (itemFeature.quantity > 0) {
                toast({
                    title: `Seleccione ${itemFeature.quantity} ${itemFeature.feature.name}`,
                    status: 'error',
                    position: 'top',
                    isClosable: true
                })
                return
            }
        }

        onDone(
            features.filter(f => f.subs.length > 0),
            counter,
            item_price_plus_features_price
        )

        onClose()
        setCounter(1)
    }

    function resetFeatures(itemFeatures: GetItem['item_features']) {
        setFeatures(
            itemFeatures.map(itemFeature => ({
                feature_id: itemFeature.feature_id,
                quantity: itemFeature.quantity,
                name: itemFeature.feature.name,
                subs: []
            }))
        )
    }

    function clearFeatures() {
        const itemFeatures = item?.item_features as GetItem['item_features']
        resetFeatures(itemFeatures)
    }

    function delFeature(subFeatureId: number, index: number) {
        const nextFeatures = [...features]
        const newFeatures = nextFeatures.map(feature => {
            const newSubs = []
            for (let i = 0; i < feature.subs.length; i++) {
                const sub = feature.subs[i]
                if (!(sub.sub_feature_id === subFeatureId && index === i)) {
                    newSubs.push(sub)
                }
            }
            feature.subs = newSubs
            return feature
        })
        setFeatures(newFeatures)
    }

    function setInformation(
        subFeatureId: number,
        index: number,
        information: string
    ) {
        const nextFeatures = [...features].map(feature => {
            const subs = feature.subs.map((_sub, i) => {
                if (_sub.sub_feature_id === subFeatureId && index === i) {
                    return { ..._sub, aditional_information: information }
                } else {
                    return _sub
                }
            })
            return {
                ...feature,
                subs
            }
        })
        setFeatures(nextFeatures)
    }

    return {
        item,
        counter,
        setCounter,
        onClose,
        isOpen,
        onOpen: _onOpen,
        features,
        setFeature: _setFeature,
        isLoading,
        setFeatures,
        clearFeatures,
        submitHandler,
        delFeature,
        setInformation
    }
}
