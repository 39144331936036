import { Global } from '@emotion/react'
import Light from '../../assets/fonts/static/RedHatDisplay-Light.ttf'
import Regular from '../../assets/fonts/static/RedHatDisplay-Regular.ttf'
import Medium from '../../assets/fonts/static/RedHatDisplay-Medium.ttf'
import SemiBold from '../../assets/fonts/static/RedHatDisplay-SemiBold.ttf'
import Bold from '../../assets/fonts/static/RedHatDisplay-Bold.ttf'
import ExtraBold from '../../assets/fonts/static/RedHatDisplay-ExtraBold.ttf'

import PLight from '../../assets/fonts/poppins/Poppins-Light.ttf'
import PRegular from '../../assets/fonts/poppins/Poppins-Regular.ttf'
import PMedium from '../../assets/fonts/poppins/Poppins-Medium.ttf'
import PSemiBold from '../../assets/fonts/poppins/Poppins-SemiBold.ttf'
import PBold from '../../assets/fonts/poppins/Poppins-Bold.ttf'

const Fonts = () => (
    <Global
        styles={`
            @font-face {
                font-family: 'RedHatDisplay-Light';
                font-style: light;
                font-weight: 300;
                font-display: swap;
                src: url('${Light}');
            }
            @font-face {
                font-family: 'RedHatDisplay-Regular';
                font-style: medium;
                font-weight: 400;
                font-display: swap;
                src: url('${Regular}');
            }
            @font-face {
                font-family: 'RedHatDisplay-Medium';
                font-style: medium;
                font-weight: 500;
                font-display: swap;
                src: url('${Medium}');
            }
            @font-face {
                font-family: 'RedHatDisplay-Semibold';
                font-style: semibold;
                font-weight: 600;
                font-display: swap;
                src: url('${SemiBold}');
            }
            @font-face {
                font-family: 'RedHatDisplay-Bold';
                font-style: bold;
                font-weight: 700;
                font-display: swap;
                src: url('${Bold}');
            }
            @font-face {
                font-family: 'RedHatDisplay-ExtraBold';
                font-style: extrabold;
                font-weight: 800;
                font-display: swap;
                src: url('${ExtraBold}');
            }

            @font-face {
                font-family: 'Poppins-Light';
                font-style: light;
                font-weight: 300;
                font-display: swap;
                src: url('${PLight}');
            }
            @font-face {
                font-family: 'Poppins-Regular';
                font-style: regular;
                font-weight: 400;
                font-display: swap;
                src: url('${PRegular}');
            }
            @font-face {
                font-family: 'Poppins-Medium';
                font-style: medium;
                font-weight: 500;
                font-display: swap;
                src: url('${PMedium}');
            }
            @font-face {
                font-family: 'Poppins-Semibold';
                font-style: semibold;
                font-weight: 600;
                font-display: swap;
                src: url('${PSemiBold}');
            }
            @font-face {
                font-family: 'Poppins-Bold';
                font-style: bold;
                font-weight: 700;
                font-display: swap;
                src: url('${PBold}');
            }
      `}
    />
)

export default Fonts
