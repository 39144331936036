import { useToast } from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import { post, put } from '../../../../lib/http'
import val from '../../../../lib/val'
import { Val } from '../../../../lib/val/type'
import extractDataForm from '../../../../utilities/extractDataForm'
import { ListCategory } from '../../types'
import { FormCategory } from './types'

export default function useSubmitCategory(
    onDone: () => void,
    edition?: ListCategory
) {
    const [isLoading, setLoading] = useState(false)
    const [helper, setHelper] = useState<Partial<FormCategory>>({})
    const toast = useToast()
    const [name, setName] = useState('')
    const [printerIds, setPrinterIds] = useState<number[]>([])

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm(ev.target)
        const v = val({
            name: form.name.trim(),
            printer_ids: printerIds
        }) as Val<FormCategory>
        v.name.isEmpty('Es requerido el nombre')
        v.printer_ids.isEmpty('Es requerido la impresora')
        const result = v.runtest()
        if (result) {
            setHelper(result)
            return
        }
        setLoading(true)
        const method = edition ? put : post
        const path = edition
            ? `/api/category_items/${edition.id}`
            : '/api/category_items'
        const { error } = await method(path, {
            name: form.name.trim(),
            printer_ids: printerIds
        })
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error'
            })
            if (error.errors) {
                setHelper(error.errors)
            }
        } else {
            onDone()
        }
        setLoading(false)
    }

    useEffect(() => {
        setHelper({ ...helper, name: '' })
    }, [name])

    useEffect(() => {
        setHelper({ ...helper, printer_ids: '' })
    }, [printerIds])

    return {
        submit,
        isLoading,
        helper,
        setHelper,
        setName,
        name,
        setPrinterIds,
        printerIds
    }
}
