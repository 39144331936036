import { Badge, Td, Text, Tr } from '@chakra-ui/react'
import { controlList } from './storage'
import List from '../../lib/list'
import TypeIdentificationDocument, {
    TypeIdentificationDocumentByCode
} from '../../utilities/type_identification_document'
import dayjs from 'dayjs'
import ModalView from './ModalContents/ModalView'
import TableActions from './TableActions'
import useStorage from '../../hooks/useStorage'
import Client from '../../models/client'

export default function TableSales() {
    const { cashboxes, business_variables } = useStorage()

    const print_sale_note = business_variables.find(
        v => v.code === '001'
    )?.value
    const generate_electronic_receipts = business_variables.find(
        v => v.code === '002'
    )?.value

    function getFullNameClient(client: Client) {
        const fullNumber = `${
            TypeIdentificationDocument[client.type_identification_document_id]
        }: ${client.number}`
        if (client.type_identification_document_id === 2) {
            return {
                fullName: client.business_name,
                fullNumber
            }
        }
        return {
            fullName: `${client.firstname} ${client.lastname}`,
            fullNumber
        }
    }

    return (
        <List
            control={controlList}
            fullWidth
            tbody={(orderPayment, e) => (
                <Tr key={e}>
                    <Td>{e}</Td>
                    <Td>
                        <ModalView
                            orderPaymentId={orderPayment.id}
                            element={click => (
                                <Text
                                    textDecoration="underline"
                                    cursor="pointer"
                                    onClick={click}
                                    color="alo-blue"
                                    fontFamily="Poppins-Semibold"
                                >
                                    {dayjs(orderPayment.created_at).format(
                                        'DD/MM/YYYY hh:mm a'
                                    )}
                                </Text>
                            )}
                        />
                    </Td>
                    <Td>
                        {
                            cashboxes.find(
                                cashbox =>
                                    cashbox.id ===
                                    orderPayment.cashbox_user.cashbox_id
                            )?.name
                        }
                    </Td>
                    <Td>
                        {orderPayment.sunat_vouchers.length > 0
                            ? orderPayment.sunat_vouchers[0].code
                            : orderPayment.code}
                    </Td>
                    <Td>
                        <Text>
                            {orderPayment.sunat_vouchers.length > 0
                                ? orderPayment.sunat_vouchers[0].client_name
                                : getFullNameClient(orderPayment.client)
                                      .fullName}
                        </Text>
                        <Text whiteSpace="nowrap" fontSize="xs">
                            {orderPayment.sunat_vouchers.length > 0
                                ? `${
                                      TypeIdentificationDocumentByCode[
                                          parseInt(
                                              orderPayment.sunat_vouchers[0]
                                                  .client_type_document
                                          )
                                      ]
                                  }: ${
                                      orderPayment.sunat_vouchers[0]
                                          .client_number_document
                                  }`
                                : getFullNameClient(orderPayment.client)
                                      .fullNumber}
                        </Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                        S/ {orderPayment.total_final.toFixed(2)}
                    </Td>
                    <Td>
                        {orderPayment.have_outstanding ? 'Crédito' : 'Contado'}
                    </Td>
                    <Td>
                        {orderPayment.account_order.status === 2 ? (
                            <Badge bg="alo-blue" color="white">
                                Vigente
                            </Badge>
                        ) : (
                            <Badge bg="error" color="white">
                                Anulado
                            </Badge>
                        )}
                    </Td>
                    <Td>
                        <TableActions
                            orderPayment={orderPayment}
                            print_sale_note={print_sale_note}
                            generate_electronic_receipts={
                                generate_electronic_receipts
                            }
                        />
                    </Td>
                </Tr>
            )}
        />
    )
}
