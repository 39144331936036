import { Box, Flex, HStack } from '@chakra-ui/react'
import NavbarAlt from '../../components/NavbarAlt'
import Initial from '../../components/Initial'
import useInitial from './useInitial'
import colors from '../../config/theme/colors'
import { storagePage } from './storage'
import TablesSection from './TablesSection'
import ProductsSection from './ProductsSection'

export default function MoveProducts() {
    const { pageState } = useInitial()
    return (
        <Box minH="100vh" bg="white">
            <NavbarAlt
                title="Mover productos"
                path={`/by_tables/${storagePage.table_id}/quick_sale`}
            />
            <Initial pageState={pageState} h="calc(100vh - 60px)">
                <HStack
                    w="full"
                    align="stretch"
                    display="flex"
                    flexDirection={{ base: 'column', md: 'row' }}
                >
                    <Flex
                        minW={{
                            xl: '550px',
                            lg: '500px'
                        }}
                        minH={{ base: 'fit-content', md: 'calc(100vh - 60px)' }}
                        color={colors['alo-gray']}
                        flexDirection="column"
                        py={6}
                    >
                        <ProductsSection />
                    </Flex>
                    <Box
                        w="full"
                        px={{
                            xl: '16',
                            base: '1'
                        }}
                        py={{
                            xl: '10',
                            base: '6'
                        }}
                        bg="base-4"
                        minH={{ base: 'fit-content', md: 'calc(100vh - 60px)' }}
                    >
                        <TablesSection />
                    </Box>
                </HStack>
            </Initial>
        </Box>
    )
}
