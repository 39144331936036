import Routes from './Routes'
import { Box } from '@chakra-ui/react'
import { ModalProvider } from '../lib/modal'
import { InitialProvider } from '../context/InitialContext'
import { useEffect } from 'react'
import './app.css'

function App() {
    useEffect(() => {
        document.oncontextmenu = () => false
        if (!localStorage.getItem('all_sales')) {
            localStorage.setItem('all_sales', '0')
        }
    }, [])

    return (
        <InitialProvider>
            <div className="App">
                <ModalProvider />
                <Box
                    w="full"
                    top="0"
                    position="relative"
                    style={{
                        minHeight: '100vh'
                    }}
                >
                    <Routes />
                </Box>
            </div>
        </InitialProvider>
    )
}

export default App
