import dayjs from 'dayjs'
import { useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { FindItemMovement } from '../modals/ModalHistories/types'
import Item from '../../../models/item'
import { get } from '../../../lib/http'

const head: (...values: string[]) => Row[number][] = (...values) =>
    values.map<Row[number]>(val => ({
        value: val,
        fontWeight: 'bold',
        wrap: true,
        alignVertical: 'center',
        align: 'left',
        fontSize: 11
    }))

export default function useExportHistory(
    item: Item,
    startDate: string,
    endDate: string
) {
    const [isLoading, setIsLoading] = useState(false)

    async function submit() {
        setIsLoading(true)
        const itemMovements = await get<FindItemMovement[]>(
            `/api/item_movements?start_date=${dayjs
                .tz(startDate + ' 00:00:00', 'America/Lima')
                .toISOString()}&end_date=${dayjs
                .tz(endDate + ' 23:59:59', 'America/Lima')
                .toISOString()}&item_id=${item.id}`
        )

        const sDate = dayjs(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        const eDate = dayjs(endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

        const rows: SheetData = [
            [],
            [
                {
                    value: `Historial - ${item.name}`,
                    fontWeight: 'bold',
                    span: 6,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 16
                }
            ],
            [
                {
                    value: `De: ${sDate} - Hasta ${eDate}`,
                    span: 6,
                    align: 'center'
                }
            ],
            [],
            head(
                'Fecha y Hora',
                'Comentario',
                'Usuario',
                'Stock Inicial',
                'Ajuste',
                'Stock Final'
            )
        ]

        itemMovements.data.result.forEach(itemMovement => {
            const user = itemMovement.user
            const fullName = user ? `${user.firstname} ${user.lastname}` : '-'

            rows.push([
                {
                    value: dayjs(itemMovement.created_at).format(
                        'DD/MM/YYYY hh:mm a'
                    ),
                    type: String,
                    align: 'left'
                },
                {
                    value: itemMovement.reason,
                    type: String
                },
                {
                    value: fullName,
                    type: String
                },
                {
                    value: itemMovement.initial_quantity,
                    type: Number
                },
                {
                    value: itemMovement.marginal_quantity,
                    type: Number
                },
                {
                    value: itemMovement.current_quantity,
                    type: Number
                }
            ])
        })

        const itemName = item.name.toLowerCase().replaceAll(' ', '_')

        await writeXlsxFile(rows, {
            fileName: `historial_${itemName}_${sDate}_${eDate}.xlsx`,
            columns: [20, 40, 20, 12, 12, 12].map(width => ({ width })),
            fontSize: 10
        })

        setIsLoading(false)
    }

    return {
        isLoading,
        submit
    }
}
