import { Flex, Select, Button } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import Tables from './Tables'
import useInitial from './useInitial'
import Zones from './Zones'
import useFormTable from './Tables/useForm'
import useFormZone from './Zones/useForm'
import Rules from '../../components/Rules'

export default function TableManagement() {
    const { tabProps } = useInitial()
    const openModalTable = useFormTable()
    const openModalZone = useFormZone()

    function getPermission() {
        switch (tabProps.value) {
            case 'table':
                return 'Crear mesa'
            case 'zone':
                return 'Crear zona'
            default:
                return 'Crear'
        }
    }

    function openModal() {
        if (tabProps.value === 'table') {
            openModalTable()
        }
        if (tabProps.value === 'zone') {
            openModalZone()
        }
    }

    return (
        <Sidebar>
            <SimpleNav title="Gestión de mesas" />
            <Tabs {...tabProps}>
                <Rules permission="Visualizar mesas">
                    <Tab value="table">Mesas</Tab>
                </Rules>
                <Rules permission="Visualizar zonas">
                    <Tab value="zone">Ambientes</Tab>
                </Rules>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <Rules permission="Visualizar mesas">
                        <option value="table">Mesas</option>
                    </Rules>
                    <Rules permission="Visualizar zonas">
                        <option value="zone">Ambientes</option>
                    </Rules>
                </Select>
                <Rules permission={getPermission()}>
                    <Button
                        variant="alo-blue:md-xl"
                        onClick={() => openModal()}
                    >
                        + Nuevo
                    </Button>
                </Rules>
            </Flex>
            {tabProps.value === 'table' && <Tables />}
            {tabProps.value === 'zone' && <Zones />}
        </Sidebar>
    )
}
