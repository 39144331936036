import { SVGProps } from 'react'

const Search = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.965 11.255H12.755L17.745 16.255L16.255 17.745L11.255 12.755V11.965L10.985 11.685C9.84497 12.665 8.36499 13.255 6.755 13.255C3.16504 13.255 0.255005 10.345 0.255005 6.755C0.255005 3.16501 3.16504 0.255005 6.755 0.255005C10.345 0.255005 13.255 3.16501 13.255 6.755C13.255 8.36499 12.665 9.845 11.6851 10.985L11.965 11.255ZM2.255 6.755C2.255 9.245 4.26501 11.255 6.755 11.255C9.245 11.255 11.255 9.245 11.255 6.755C11.255 4.26501 9.245 2.255 6.755 2.255C4.26501 2.255 2.255 4.26501 2.255 6.755Z"
        />
    </svg>
)

export default Search
