export default function ArrowUp(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.25 25V9.787l6.988 6.988L25 15 15 5 5 15l1.763 1.762 6.987-6.975V25h2.5Z" />
        </svg>
    )
}
