import ColorType from '../../types/colorType'

const colors: Record<ColorType, string> = {
    primary: '#E1453D',
    secondary: '#FE6A5E',
    accent: '#F8C765',
    'alo-blue': '#4E6BD3',
    'alo-red': '#FE6A5E',
    yellow: '#FFC82C',
    'alo-green': '#44C281',
    orange: '#FF9933',
    purple: '#9641B4',
    turquoise: '#44C2C2',
    black: '#000000',
    dark: '#262C2F',
    'dark-2': '#334148',
    'alo-gray': '#434343',
    'gray-2': '#334148',
    'gray-3': '#708595',
    white: '#FFFFFF',
    base: '#FAFAFA',
    'base-2': '#F9F9F9',
    'base-3': '#F6F6F6',
    'base-4': '#ECECEC',
    success: '#44C281',
    error: '#FE6A5E'
}

export default colors
