import { Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import colors from '../../../config/theme/colors'
import FormClient from '../../../components/ModalFormClient'
import SelectClient from '../../../components/ModalSelectClient'
import Client from '../../../models/client'
import { storagePage } from '../storage'
import { useRender } from 'redity'
import TypeIdentificationDocument from '../../../utilities/type_identification_document'

export default function ClientSection() {
    const r = useRender()
    const clientSelected = storagePage.clientSelected as Client

    const type_identification_document_id =
        clientSelected.type_identification_document_id

    function selectHandler(client: Client) {
        storagePage.clientSelected = client
        r()
    }

    return (
        <Flex alignItems="flex-start" w="full">
            <Box>
                <SelectClient
                    element={click => (
                        <Heading
                            fontSize="lg"
                            cursor="pointer"
                            textDecoration="underline"
                            color="alo-blue"
                            onClick={click}
                        >
                            {clientSelected?.business_name ||
                                `${clientSelected?.firstname} ${clientSelected?.lastname}`}
                        </Heading>
                    )}
                    onSelect={selectHandler}
                />
                <Text fontSize="xs" opacity=".7">
                    <Text as="b">
                        {
                            TypeIdentificationDocument[
                                type_identification_document_id
                            ]
                        }
                        :{' '}
                    </Text>
                    {clientSelected?.number}
                </Text>
            </Box>
            <FormClient
                edition={clientSelected}
                element={click => (
                    <IconButton
                        ml={4}
                        icon={<Pencil fill="white" />}
                        aria-label="Edición"
                        bg={colors.orange}
                        size="sm"
                        onClick={click}
                        isDisabled={clientSelected.id === 1}
                        _hover={{}}
                        _active={{}}
                    />
                )}
                onDone={selectHandler}
            />
        </Flex>
    )
}
