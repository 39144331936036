import React from 'react'
import ReactDOM from 'react-dom'
import 'dayjs/locale/es'
import { ChakraProvider } from '@chakra-ui/react'
import { HashRouter } from 'react-router-dom'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import theme from './config/theme'
import { DSN, NODE_ENV, PUBLIC_URL } from './config/constants'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Fonts from './config/theme/fonts'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AuthProvider } from './context/AuthContext'
import { RunwayProvider } from './context/RunwayContext'

if (NODE_ENV !== 'development') {
    Sentry.init({
        dsn: DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })
}

dayjs.locale('es')
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
    <React.StrictMode>
        <HashRouter basename={PUBLIC_URL}>
            <ChakraProvider theme={theme}>
                <AuthProvider>
                    <RunwayProvider>
                        <Fonts />
                        <App />
                    </RunwayProvider>
                </AuthProvider>
            </ChakraProvider>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
