import { listCategory } from '../storage'
import DeleteCategory from './DeleteCategory'
import Edition from './Edition'
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd'
import { Badge, Box, Icon, SimpleGrid, useToast } from '@chakra-ui/react'
import colors from '../../../config/theme/colors'
import Apps from '../../../components/Icon/Apps'
import { put } from '../../../lib/http'
import { useState } from 'react'
import Rules from '../../../components/Rules'

export default function Row() {
    const [isDisabledDrop, setDisabledDrop] = useState(false)
    const data = listCategory.useData()
    const toast = useToast()

    async function dragEndHandler(result: DropResult) {
        if (!result.destination) return
        setDisabledDrop(true)

        const originIndex = result.source.index
        const destinationIndex = result.destination.index

        const toTop = originIndex > destinationIndex

        const dataOrigin = listCategory.data[originIndex]

        const newCategoryItems = []

        for (let i = 0; i < listCategory.data.length; i++) {
            const categoryItem = listCategory.data[i]
            if (i === originIndex) continue
            if (toTop) {
                if (i === destinationIndex) {
                    newCategoryItems.push(dataOrigin)
                }
                newCategoryItems.push(categoryItem)
            } else {
                newCategoryItems.push(categoryItem)
                if (i === destinationIndex) {
                    newCategoryItems.push(dataOrigin)
                }
            }
        }

        listCategory.setData(newCategoryItems)

        const { error } = await put(
            `/api/category_items/${dataOrigin.id}/change_order`,
            {
                order: destinationIndex + 1
            }
        )

        if (error) {
            toast({
                title: `Hubo problemas al actualizar`,
                position: 'top',
                isClosable: true,
                status: 'error'
            })
        }
        setDisabledDrop(false)
    }

    return (
        <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable-1">
                {provided => (
                    <>
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {data.map((categoryItem, i) => (
                                <Draggable
                                    key={categoryItem.id}
                                    draggableId={categoryItem.id.toString()}
                                    index={i}
                                >
                                    {_provided => (
                                        <SimpleGrid
                                            ref={_provided.innerRef}
                                            columns={3}
                                            textAlign="center"
                                            className="row tr"
                                            {..._provided.draggableProps}
                                        >
                                            <Box
                                                color="gray.600"
                                                py="4"
                                                position="relative"
                                                pl="8"
                                            >
                                                <Box
                                                    position="absolute"
                                                    left="2"
                                                    top="4"
                                                    {..._provided.dragHandleProps}
                                                    {...(isDisabledDrop
                                                        ? {
                                                              pointerEvents:
                                                                  'none'
                                                          }
                                                        : {})}
                                                >
                                                    <Icon
                                                        fill={colors['gray-3']}
                                                        as={Apps}
                                                        fontSize="20"
                                                    />
                                                </Box>
                                                {categoryItem.name}
                                            </Box>
                                            <Box color="gray.600" py="4">
                                                {categoryItem.category_item_printers.map(
                                                    cip => (
                                                        <Badge
                                                            mx={1}
                                                            key={cip.id}
                                                            bg={
                                                                colors[
                                                                    'alo-blue'
                                                                ]
                                                            }
                                                            variant="solid"
                                                            textTransform="capitalize"
                                                            borderRadius="full"
                                                            px={2}
                                                        >
                                                            {cip.printer.name}
                                                        </Badge>
                                                    )
                                                )}
                                                {categoryItem
                                                    .category_item_printers
                                                    .length === 0 &&
                                                    categoryItem.printer
                                                        ?.name && (
                                                        <Badge
                                                            bg={
                                                                colors[
                                                                    'alo-blue'
                                                                ]
                                                            }
                                                            variant="solid"
                                                            textTransform="capitalize"
                                                            borderRadius="full"
                                                            px={2}
                                                        >
                                                            {
                                                                categoryItem
                                                                    .printer
                                                                    .name
                                                            }
                                                        </Badge>
                                                    )}
                                            </Box>
                                            <Box color="gray.600" py="4">
                                                <Rules permission="Editar categoría">
                                                    <Edition
                                                        category={categoryItem}
                                                    />
                                                </Rules>
                                                <Rules permission="Eliminar categoría">
                                                    <DeleteCategory
                                                        category={categoryItem}
                                                    />
                                                </Rules>
                                            </Box>
                                        </SimpleGrid>
                                    )}
                                </Draggable>
                            ))}
                        </Box>
                        {provided.placeholder}
                    </>
                )}
            </Droppable>
        </DragDropContext>
    )
}
