import { ReactNode } from 'react'
import PageState from '../../types/pageState'
import { Text, Box, Spinner, Flex } from '@chakra-ui/react'

export default function Initial({
    pageState,
    h = '100vh',
    children
}: PropsInitial) {
    if (pageState === PageState.LOADING) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                h={h}
                alignItems="center"
            >
                <Spinner size="md" />
            </Box>
        )
    }

    if (pageState === PageState.ERROR) {
        return (
            <Flex justifyContent="center" p="8">
                <Text textAlign="center" color="gray-3">
                    Ha ocurrido un error con el servidor y la petición no podría
                    completarse. <br />
                    Por favor intente refrescar la página.
                </Text>
            </Flex>
        )
    }

    return <>{children}</>
}

interface PropsInitial {
    pageState: PageState
    children: ReactNode
    h?: string
}
