import { Box, Flex } from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import Initial from '../../components/Initial'
import PageState from '../../types/pageState'
import MenuUser from '../../components/MenuUser'
import NTabs from '../../components/NTabs'
import Tables from './Tables'
import TabZones from './TabZones'
import SelectZones from './SelectZones'
import { useState, useEffect } from 'react'
import { useGet } from '../../hooks/useHttp'
import Zone from '../../models/zone'

export default function ByTables() {
    const { data } = useGet<Zone[]>(
        '2>/api/zones/?sort=name&is_enabled=true',
        []
    )
    const [zoneId, setZoneId] = useState(
        () => Number(localStorage.getItem('zone_id')) || 0
    )

    useEffect(() => {
        if (data.result.length > 0) {
            const currentZoneId = Number(localStorage.getItem('zone_id'))
            newSetZoneId(currentZoneId || data.result[0].id)
        }
    }, [data.result])

    function newSetZoneId(_zoneId: number) {
        setZoneId(_zoneId)
        localStorage.setItem('zone_id', _zoneId.toString())
    }

    return (
        <Initial pageState={PageState.SUCCESS}>
            <Navbar component={<NTabs />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box
                w={{
                    base: '100vw',
                    xl: 'calc(100vw - 250px)'
                }}
                px={{
                    xl: 12,
                    lg: 6
                }}
                py={6}
                mx="auto"
            >
                <Box mb={5} px="16px">
                    <Flex justifyContent="end" alignItems="center">
                        <Box mr="auto" display={{ base: 'block', md: 'none' }}>
                            {zoneId !== 0 && (
                                <SelectZones
                                    zones={data.result}
                                    currentZone={zoneId}
                                    onClick={newSetZoneId}
                                />
                            )}
                        </Box>
                        <Box
                            rounded="xl"
                            color="white"
                            px={5}
                            mx="2px"
                            bg="gray-3"
                            textAlign={'center'}
                            h={6}
                        >
                            <Box display={{ base: 'inline', md: 'none' }}>
                                Disp.
                            </Box>
                            <Box display={{ base: 'none', md: 'inline' }}>
                                Disponible
                            </Box>
                        </Box>
                        <Box
                            rounded="xl"
                            color="white"
                            px={5}
                            mx="2px"
                            h={6}
                            bg="primary"
                            textAlign={'center'}
                        >
                            <Box display={{ base: 'inline', md: 'none' }}>
                                Ocup.
                            </Box>
                            <Box display={{ base: 'none', md: 'inline' }}>
                                Ocupada
                            </Box>
                        </Box>
                    </Flex>
                    <TabZones
                        zones={data.result}
                        currentZone={zoneId}
                        onClick={newSetZoneId}
                    />
                </Box>
                {zoneId !== 0 && <Tables zoneId={zoneId} />}
            </Box>
        </Initial>
    )
}
