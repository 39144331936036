import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { render, useRender } from 'redity'
import BoxTotal from '../../../components/BoxTotal'
import colors from '../../../config/theme/colors'
import { get } from '../../../lib/http'
import FormSplit from '../Modals/FormSplit'
import Keys from '../keys'
import { storagePage } from '../storage'
import useInitial from '../useInitial'
import ListItems from './ListItems'
import { OrderAccount } from '../types'

export default function OrderItems() {
    useRender(Keys.ORDER_ITEMS)

    const total = storagePage.matrix_items
        .map(order_item => order_item.final_price_item)
        .reduce((prev, curr) => prev + curr, 0)

    const { order_id } = useInitial()

    async function onDone() {
        const { data } = await get<OrderAccount>(
            `/api/account_orders/${order_id}/order`
        )
        storagePage.account_orders = data.result.account_orders
        storagePage.matrix_items = data.result.matrix_items
        storagePage.items = []
        storagePage.items_to_divide = []
        render(Keys.ORDER_ITEMS)
        render(Keys.ORDER_ACCOUNTS)
    }

    return (
        <form>
            <Box
                bg="white"
                py="8"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                minH="calc(100vh - 60px)"
                minW={{ base: '100%', md: '450px' }}
            >
                <Box>
                    <Heading size="md" mb={6} px="8">
                        Detalle de pedido
                    </Heading>
                    <ListItems />
                </Box>
                <Box px="8">
                    <BoxTotal border>
                        <Text fontSize="20px">TOTAL</Text>
                        <Text fontSize="20px">S/ {total.toFixed(2)}</Text>
                    </BoxTotal>
                    <FormSplit
                        onDone={onDone}
                        element={click => (
                            <Button
                                bg={colors['alo-blue']}
                                color="white"
                                w="full"
                                mt={{ base: 2, md: 6 }}
                                size="lg"
                                minH="48px"
                                borderRadius="xl"
                                isDisabled={
                                    storagePage.items.length < 1 ? true : false
                                }
                                onClick={click}
                                _hover={{}}
                                type={'reset'}
                            >
                                Dividir
                            </Button>
                        )}
                    />
                </Box>
            </Box>
        </form>
    )
}
