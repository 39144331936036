import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    Icon,
    Text
} from '@chakra-ui/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import useAuth from '../../hooks/useAuth'
import useSession from '../../hooks/useSession'
import Bell from '../Icon/Bell'
import Change from '../Icon/Change'
import CashRegister from '../Icon/CashRegister'
import DashBoardIcon from '../Icon/DashBoardIcon'
import Grid from '../Icon/Grid'
import LogOut from '../Icon/LogOut'
import CashIcon from '../Icon/CashIcon'
import Modals from './Modals'

export default function MenuUser() {
    const { signOut } = useAuth()
    const { session, isLogged, isModuleAccesible, isCUAccesible } = useSession()
    const { onOpen, onClose } = useDisclosure()
    const { push } = useHistory()
    const [openModal, setOpenModal] = useState<boolean>(false)

    const permissions = [
        {
            group: 'Productos',
            access: isCUAccesible([
                'Visualizar productos',
                'Visualizar categorías',
                'Visualizar insumos',
                'Visualizar características'
            ]),
            path: '/cards'
        },
        {
            group: 'Mesas',
            access: isCUAccesible(['Visualizar mesas', 'Visualizar zonas']),
            path: '/tables'
        },
        {
            group: 'Resúmenes diarios',
            access: isCUAccesible('Visualizar resúmenes diarios'),
            path: '/statement'
        },
        {
            group: 'Impresoras',
            access: isCUAccesible('Visualizar impresoras'),
            path: '/printerMachines'
        }
    ]

    let route = '/cards'

    const defaultRoute = permissions.find(
        element => element.access == true
    )?.path

    if (!permissions[0].access && defaultRoute) {
        route = defaultRoute
    }

    return (
        <>
            <Modals openModal={openModal} setOpenModal={setOpenModal} />
            <Box position="relative" display="none">
                <Icon as={Bell} fill="inherit" mr={14} fontSize="4xl" />
                <Box
                    bg="orange"
                    w="5"
                    h="5"
                    position="absolute"
                    rounded="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    top="5"
                    left="5"
                    fontSize="xs"
                    color="white"
                >
                    9
                </Box>
            </Box>
            <Menu autoSelect={false}>
                <MenuButton
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    h="50px"
                >
                    <Box alignItems="center" gridGap="24px" display="flex">
                        <Box
                            textAlign="right"
                            display={{ base: 'none', lg: 'block' }}
                        >
                            <Text fontWeight="semibold" mb={-1}>
                                {session ? session.user.firstname : 'Mozo'}
                            </Text>
                            <Text
                                fontWeight="semibold"
                                fontSize="xs"
                                color="gray-3"
                            >
                                {session ? session.role.name : ''}
                            </Text>
                        </Box>
                        <Box
                            bg="orange"
                            w="10"
                            h="10"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="9px"
                            fontWeight="bold"
                            color="white"
                        >
                            {session
                                ? session.user.firstname.charAt(0).toUpperCase()
                                : 'M'}
                        </Box>
                    </Box>
                </MenuButton>
                <MenuList
                    borderRadius="10px"
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    zIndex={100}
                >
                    {session && isModuleAccesible('Administración') && (
                        <>
                            {isModuleAccesible('Administración') && (
                                <MenuItemCustom onClick={() => push(route)}>
                                    <DashBoardIcon
                                        fontSize="24"
                                        fill="#434343"
                                    />
                                    Módulo de Administración
                                </MenuItemCustom>
                            )}
                            {isModuleAccesible('Caja') && (
                                <MenuItemCustom
                                    onClick={() => push('/cash_register')}
                                >
                                    <CashRegister
                                        fontSize="24"
                                        fill="#434343"
                                    />
                                    Caja
                                </MenuItemCustom>
                            )}
                            {isModuleAccesible('Venta rápida') && (
                                <MenuItemCustom
                                    onClick={() => push('/quick_sale')}
                                >
                                    <CashIcon fontSize="24" fill="#434343" />
                                    Venta rápida
                                </MenuItemCustom>
                            )}
                            {isModuleAccesible('Mesas') && (
                                <MenuItemCustom
                                    onClick={() => push('/by_tables')}
                                >
                                    <Icon
                                        as={Grid}
                                        fill="inherit"
                                        mr="1"
                                        fontSize="lg"
                                    />
                                    Mesas
                                </MenuItemCustom>
                            )}
                        </>
                    )}
                    {isLogged ? (
                        <>
                            <MenuItemCustom onClick={() => setOpenModal(true)}>
                                <Change fontSize="24" fill="#434343" />
                                Cambiar contraseña
                            </MenuItemCustom>
                            <MenuItemCustom onClick={() => signOut()}>
                                <LogOut fontSize="24" />
                                Cerrar sesión
                            </MenuItemCustom>
                        </>
                    ) : (
                        <MenuItemCustom onClick={() => push('/')}>
                            <LogOut fontSize="24" />
                            Iniciar Sesión
                        </MenuItemCustom>
                    )}
                </MenuList>
            </Menu>
        </>
    )
}

function MenuItemCustom({
    children,
    onClick
}: {
    children: any
    onClick: () => void
}) {
    return (
        <MenuItem
            display="flex"
            gridGap="10px"
            color="#434343"
            alignItems="center"
            onClick={onClick}
        >
            {children}
        </MenuItem>
    )
}
