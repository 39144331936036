import {
    Text,
    FormLabel,
    FormControl,
    Select,
    Input,
    SelectProps,
    InputProps
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import { useGet } from '../../../hooks/useHttp'
import useSession from '../../../hooks/useSession'
import CashBox from '../../../models/cashbox'
import { formOpenCashbox } from '../storage'
import onlyNumber from '../../../utilities/onlyNumber'

export default function ModalOpenTheBox() {
    return (
        <>
            <Text
                textAlign="center"
                mt="2"
                mb="5"
                color="#434343"
                fontSize="2xl"
                fontWeight="bold"
            >
                Aperturar caja
            </Text>
            <Text mb="5" mt="5" color="#434343">
                Para realizar el registro de la apertura, detalle la siguiente
                información.
            </Text>
            <CashboxField />
            <AmountField />
        </>
    )
}

function CashboxField() {
    const { isAdmin } = useSession()
    const { data } = useGet<CashBox[]>(
        '/api/cashboxes?sort=name&is_free=true',
        []
    )
    const { props, helper } =
        formOpenCashbox.useField<SelectProps>('cashbox_id')

    function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        const casbbox = data.result.find(
            cbx => cbx.id.toString() === ev.target.value
        ) as CashBox
        formOpenCashbox.store.set('cashbox_name', casbbox.name)
        props.onChange(ev)
    }

    return (
        <FormControl mb="4">
            <FormLabel>Caja</FormLabel>
            {!isAdmin() && (
                <Select
                    {...props}
                    onChange={changeHandler}
                    borderRadius="5px"
                    placeholder="Seleccionar la caja"
                    _hover={{}}
                    autoFocus
                    isDisabled={isAdmin()}
                >
                    {data.result.map((cashBox: any) => (
                        <option key={cashBox.id} value={cashBox.id}>
                            {cashBox.name}
                        </option>
                    ))}
                </Select>
            )}
            {isAdmin() && (
                <Input
                    value={formOpenCashbox.store().cashbox_name}
                    isReadOnly
                />
            )}
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function AmountField() {
    const { props, helper } = formOpenCashbox.useField<InputProps>('amount')

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max: Infinity,
            negative: false
        })
        props.onChange({
            ...ev,
            target: {
                ...ev.target,
                value
            }
        })
    }

    return (
        <FormControl mb="4">
            <FormLabel>Monto de apertura (S/)</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar monto de apertura"
                onChange={changeHandler}
                autoFocus
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
