import { Box, Checkbox, Flex, Icon, Text } from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import CashRegister from '../Icon/CashRegister'
import CashIcon from '../Icon/CashIcon'
import Description from '../Icon/Description'
import Grid from '../Icon/Grid'
import useNTabs from './useNTabs'

const displayLabels = { base: 'none', md: 'block' }

export default function NTabs({
    onSell = () => null
}: {
    onSell?: (isSell: boolean) => void
}) {
    const {
        isActive,
        isLogged,
        isWaiter,
        isOpenOptionsSell,
        setIsOpenOptionsSell,
        isCheckedSell,
        setCheckedSell,
        ref
    } = useNTabs(onSell)

    return (
        <Flex>
            {isLogged && !isWaiter() && (
                <Flex
                    {...isActive('/quick_sale')}
                    mr={{ base: '10px', sm: '24px' }}
                >
                    <Icon as={CashIcon} fill="inherit" mr="1" fontSize="lg" />
                    <Box display={displayLabels}>Venta rápida</Box>
                </Flex>
            )}
            <Flex {...isActive('/by_tables')} mr={{ base: '10px', sm: '24px' }}>
                <Icon as={Grid} fill="inherit" mr="1" fontSize="lg" />
                <Box display={displayLabels}>Mesas</Box>
            </Flex>
            {isLogged && !isWaiter() && (
                <Flex
                    {...isActive('/cash_register')}
                    mr={{ base: '10px', sm: '24px' }}
                >
                    <Icon
                        as={CashRegister}
                        mr="1"
                        fontSize="lg"
                        fill="inherit"
                    />
                    <Box display={displayLabels}>Caja</Box>
                </Flex>
            )}
            {isLogged && !isWaiter() && (
                <Flex
                    {...isActive('/sales')}
                    position="relative"
                    onContextMenu={() => setIsOpenOptionsSell(true)}
                >
                    <Icon
                        as={Description}
                        mr="1"
                        fontSize="lg"
                        fill="inherit"
                    />
                    <Box display={displayLabels}>Ventas</Box>
                    <Box
                        position="absolute"
                        bg="white"
                        top="10"
                        shadow="md"
                        borderRadius="md"
                        zIndex="10"
                        overflow="hidden"
                        ref={ref}
                        display={isOpenOptionsSell ? 'auto' : 'none'}
                    >
                        <Text
                            color={colors.dark}
                            fontSize="xs"
                            p="2"
                            display="flex"
                            justifyContent="space-between"
                            cursor="pointer"
                            whiteSpace="nowrap"
                            as="label"
                            _hover={{ bg: 'gray.50' }}
                        >
                            Todas las ventas
                            <Checkbox
                                ml={3}
                                size="sm"
                                isChecked={isCheckedSell}
                                onChange={setCheckedSell}
                            />
                        </Text>
                    </Box>
                </Flex>
            )}
        </Flex>
    )
}
