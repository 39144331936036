import { useContext } from 'react'
import access from '../config/access'
import AuthContext from '../context/AuthContext'
import useStorage from './useStorage'

export default function useSession() {
    const { session } = useContext(AuthContext)

    function isAdmin() {
        if (!session) return false

        return !!(session.role.id === 1)
    }

    function isCashier() {
        if (!session) return false

        return !!(session.role.id === 2)
    }

    function isWaiter() {
        if (!session) return false

        return !!(session.role.id === 3)
    }

    function getAccessRoutes() {
        let role_id = 3
        if (session) {
            role_id = session.role.id
        }

        return access[role_id].routes
    }

    function isCUAccesible(permissions: string | string[]) {
        //const { isAdmin } = useSession()
        //if (isAdmin()) return true
        let isAccesible = false
        if (Array.isArray(permissions)) {
            isAccesible = !!session?.permissions.find(x =>
                permissions.find(_cu => _cu === x.name)
            )
        } else {
            isAccesible = !!session?.permissions.find(
                x => x.name === permissions
            )
        }
        return isAccesible
    }

    function isModuleAccesible(modules: string | string[]) {
        let isAccesible = false
        if (Array.isArray(modules)) {
            isAccesible = !!session?.alaorden_modules.find(module =>
                modules.find(_module => _module === module.name)
            )
        } else {
            isAccesible = !!session?.alaorden_modules.find(
                module => module.name === modules
            )
        }
        return isAccesible
    }

    function getRedirect() {
        let role_id = 3
        if (session) {
            role_id = session.role.id
        }

        if (
            isAdmin() &&
            !isCUAccesible([
                'Visualizar productos',
                'Visualizar categorías',
                'Visualizar insumos',
                'Visualizar catacterísticas'
            ])
        ) {
            return '/dashboard'
        }

        return access[role_id].redirect
    }

    function userCanCancelItems() {
        const { business_variables } = useStorage()
        const disable_cancel_items_by_cashier = business_variables.find(
            v => v.code === '012'
        )?.value

        return (
            isAdmin() ||
            (isCashier() && disable_cancel_items_by_cashier === '0')
        )
    }

    return {
        isAdmin,
        isCashier,
        isWaiter,
        getAccessRoutes,
        getRedirect,
        isLogged: !!session,
        session,
        isCUAccesible,
        isModuleAccesible,
        userCanCancelItems
    }
}
