import { Box, Heading, Icon, Image, Text } from '@chakra-ui/react'
import ArrowLeft from '../Icon/ArrowLeft'
import logo from '../../assets/logo-alt.svg'
import { useHistory } from 'react-router-dom'
import colors from '../../config/theme/colors'

export default function NavbarAlt({ title, path }: NavbarAltProps) {
    const { goBack, push } = useHistory()

    return (
        <Box
            w="full"
            bg={colors.dark}
            color={colors.white}
            h="60px"
            display="flex"
            justifyContent={{ base: 'flex-start', lg: 'space-between' }}
            px={{ base: '4', md: '8' }}
            alignItems="center"
            shadow="sm"
        >
            <Box
                cursor="pointer"
                onClick={path ? () => push(path) : goBack}
                display={{ base: 'block', lg: 'none' }}
                mr="20px"
            >
                <Icon as={ArrowLeft} fill={colors.white} />
            </Box>
            <Box display="flex" alignItems="center">
                <Image
                    minW="80px"
                    h="45px"
                    src={logo}
                    alt="logo"
                    mr={12}
                    display={{ base: 'none', lg: 'block' }}
                />
                <Heading size="md">{title}</Heading>
            </Box>
            <Box
                cursor="pointer"
                onClick={path ? () => push(path) : goBack}
                display={{ base: 'none', lg: 'block' }}
            >
                <Icon as={ArrowLeft} fill={colors.white} />
                <Text display="inline" ml={2}>
                    VOLVER
                </Text>
            </Box>
        </Box>
    )
}

export interface NavbarAltProps {
    title: string
    path?: string
}
