import { Box, Button } from '@chakra-ui/react'
import List from '../../../lib/list'
import CardTable from './CardTable'
import Filters from './Filters'
import { listTable } from '../storage'
import useFormTable from './useForm'
import Rules from '../../../components/Rules'

export default function Tables() {
    const openModal = useFormTable()

    return (
        <>
            <Box
                marginBottom="5"
                display="flex"
                justifyContent="space-between"
                mt={6}
            >
                <Filters />
                <Rules permission="Crear mesa">
                    <Button
                        variant="alo-blue:md-xl"
                        onClick={() => openModal()}
                        display={{ base: 'none', md: 'inline' }}
                        ml="2"
                    >
                        Nueva mesa
                    </Button>
                </Rules>
            </Box>
            <List
                control={listTable}
                type="grid"
                tbody={table => <CardTable key={table.id} table={table} />}
            />
        </>
    )
}
