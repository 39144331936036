import { useEffect } from 'react'
import useSession from '../../hooks/useSession'
import useTab from '../../hooks/useTab'
import { listTable, listZone } from './storage'

export default function useInitial() {
    const { isCUAccesible } = useSession()
    let props = useTab('table')
    if (!isCUAccesible('Visualizar mesas')) {
        props = useTab('zone')
    }

    useEffect(() => {
        listTable.load()
        listZone.load()
    }, [])

    return {
        tabProps: props
    }
}
