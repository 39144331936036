import { Box, Button, Input, SimpleGrid } from '@chakra-ui/react'
import { ChangeEvent, createRef, useEffect } from 'react'
import { render, useRender } from 'redity'
import colors from '../../../config/theme/colors'
import { Keys } from '../constants'
import { storagePage } from '../storage'

export default function TypeConsumption() {
    useRender(Keys.TYPE_CONSUMPTION)
    const refInput = createRef<HTMLInputElement>()

    useEffect(() => {
        if (!refInput.current) return
        refInput.current.focus()
    }, [refInput.current])

    const { to } = storagePage

    const propsActive = {
        bg: colors.success,
        color: 'white'
    }
    const propsDeactive = {
        colorScheme: 'blackAlpha',
        variant: 'outline'
    }

    function clickHandler(_to: typeof storagePage.to) {
        storagePage.to = _to
        const elem = refInput.current as HTMLInputElement
        elem.focus()
        render(Keys.TYPE_CONSUMPTION)
        render(Keys.DETAILS)
    }

    function aliasHandler(ev: ChangeEvent<HTMLInputElement>) {
        storagePage.alias = ev.target.value
        render(Keys.DETAILS)
    }

    return (
        <Box
            w="full"
            bg={colors['base-3']}
            px={{ base: 2, md: 4 }}
            py="2"
            mt={4}
        >
            <SimpleGrid columns={3} gap={2} mb={2}>
                <Button
                    size="sm"
                    {...(to === 'eat' ? propsActive : propsDeactive)}
                    onClick={() => clickHandler('eat')}
                    _hover={{}}
                    _active={{}}
                >
                    Para comer
                </Button>
                <Button
                    size="sm"
                    {...(to === 'carry_out' ? propsActive : propsDeactive)}
                    onClick={() => clickHandler('carry_out')}
                    _hover={{}}
                    _active={{}}
                >
                    Para llevar
                </Button>
                <Button
                    size="sm"
                    {...(to === 'delivery' ? propsActive : propsDeactive)}
                    onClick={() => clickHandler('delivery')}
                    _hover={{}}
                    _active={{}}
                >
                    Delivery
                </Button>
            </SimpleGrid>
            <Input
                w="full"
                placeholder="Nombre alias"
                size="sm"
                bg="white"
                rounded="xl"
                //isReadOnly={to === 'eat'}
                onChange={aliasHandler}
                maxLength={180}
                ref={refInput}
                _readOnly={{
                    opacity: '.6'
                }}
            />
        </Box>
    )
}
