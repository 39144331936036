import UserAccess from '../types/user_access'

const access: Record<number, UserAccess> = {
    1: {
        role: 'administrador',
        role_id: 1,
        routes: [
            '/orders',
            '/cards',
            '/users',
            '/tables',
            '/confirm',
            '/quick_sale',
            '/cash_register',
            '/sales',
            '/proof',
            '/statement',
            '/by_tables',
            '/by_tables/:table_id/quick_sale',
            '/printerMachines',
            '/orders/:order_id/confirm',
            '/reports',
            '/reports/receipts',
            '/reports/business_line',
            '/dashboard',
            '/warehouses',
            '/orders/:order_id/confirm/split',
            '/orders/:order_id/confirm/split/:account_id',
            '/pendingAccounts',
            '/generateVoucher',
            '/orders/:order_id/move_products',
            '/inventories',
            '/sales/:order_payment_id/update_payments'
        ],
        redirect: '/cards'
    },
    2: {
        role: 'cajero',
        role_id: 2,
        routes: [
            '/cards',
            '/tables',
            '/printerMachines',
            '/quick_sale',
            '/checkin/:order_id',
            '/cash_register',
            '/sales',
            '/confirm',
            '/proof',
            '/statement',
            '/by_tables',
            '/by_tables/:table_id/quick_sale',
            '/orders/:order_id/confirm',
            '/reports',
            '/reports/receipts',
            '/reports/business_line',
            '/warehouses',
            '/orders/:order_id/confirm/split',
            '/orders/:order_id/confirm/split/:account_id',
            '/orders/:order_id/move_products',
            '/inventories',
            '/sales/:order_payment_id/update_payments'
        ],
        redirect: '/quick_sale'
    },
    3: {
        role: 'mozo',
        role_id: 3,
        routes: [
            '/',
            '/orders',
            '/by_tables',
            '/by_tables/:table_id/quick_sale'
        ],
        redirect: '/by_tables'
    }
}

export default access
