import { useToast } from '@chakra-ui/react'
import useSession from '../../hooks/useSession'
import useStorage from '../../hooks/useStorage'
import useSubmit from '../../hooks/useSubmit'
import modal from '../../lib/modal'
import Role from '../../models/role'
import User from '../../models/user'
import FormUser from './FormUser'
import { controlList, formUser } from './storage'
import { FormSubmit } from './types'
import useAuth from '../../hooks/useAuth'
import RoleUser from '../../models/role_user'

export default function useForm(user?: User & { roles_users: RoleUser[] }) {
    const { updateUser } = useAuth()
    const { session } = useSession()
    const { roles } = useStorage()
    const toast = useToast()
    const { submit, validation, observer } = useSubmit(formUser, {
        path: user ? `/api/users/${user.id}` : '/api/users',
        method: user ? 'put' : 'post',
        done: onDone,
        error: e => {
            if (e.type !== 'customer') {
                toast({
                    title: e.message,
                    position: 'top',
                    status: 'error'
                })
            }
        }
    })

    function onDone() {
        if (user && session && user.id === session.user.id) {
            const { firstname, lastname, username, role_id } = formUser.store()
            const role = roles.find(
                _role => _role.id === parseInt(role_id)
            ) as Role

            updateUser({ firstname, lastname, username }, role)
        }
        controlList.load()
    }

    validation((v, f) => {
        v.firstname.isEmpty('El nombre es requerido')
        v.lastname.isEmpty('El apellido es requerido')
        v.role_id.isEmpty('Selecciona un perfil')
        if (f.role_id === '1' || f.role_id === '2') {
            v.username.isEmpty('El nombre de usuario es requerido')
            v.password
                .minLength(6, 'La contraseña mínima es de 6 carácteres')
                .isEmpty('La contraseña es requerido')
        } else if (!user) {
            v.pin.isEmpty('Genere un nuevo pin')
        }
    })

    observer<FormSubmit>(f => ({
        firstname: f.firstname,
        lastname: f.lastname,
        username: f.username || null,
        password: f.password || null,
        role_id: parseInt(f.role_id),
        pin: f.pin || null
    }))

    function openModal() {
        if (user) {
            formUser.store({
                firstname: user.firstname,
                lastname: user.lastname,
                username: user.username || '',
                //role_id: user.role.id.toString(),
                role_id: user.roles_users[0].role_id.toString(),
                password: user.password || '',
                pin: user.pin || ''
            })
            formUser.init(formUser.store)
        } else {
            formUser.init()
        }
        modal(FormUser, {
            title: user ? 'Editar usuario' : 'Nuevo usuario',
            size: '2xl',
            onSubmit: submit
        })
    }

    return {
        openModal
    }
}
